import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import css from './userProfileAboutMe.module.sass';

interface Props {
  aboutText: string;
}

const MAX_ABOUT_TEXT_LENGTH = 150;
const MAX_ABOUT_TEXT_ROWS = 4;

export const UserProfileAboutMe: React.FC<Props> = memo(({ aboutText }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const textShouldNotBeExpanded = useMemo(
    () =>
      aboutText.length < MAX_ABOUT_TEXT_LENGTH &&
      aboutText.split('\n').length < 4,
    [aboutText]
  );

  const textToShow = useMemo(() => {
    if (!isExpanded) return aboutText;

    return `${aboutText
      .split('\n')
      .slice(0, MAX_ABOUT_TEXT_ROWS)
      .join('\n')
      .slice(0, MAX_ABOUT_TEXT_LENGTH)}${
      !textShouldNotBeExpanded ? '...' : ''
    }`;
  }, [aboutText, isExpanded, textShouldNotBeExpanded]);

  const toggleText = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  useEffect(() => {
    if (textShouldNotBeExpanded) {
      setIsExpanded(false);
    }
  }, [textShouldNotBeExpanded]);

  return (
    <div className={css.root}>
      <p className={css.text} onClick={toggleText}>
        {textToShow}
      </p>
      {!textShouldNotBeExpanded && isExpanded && (
        <p className={css.readMore} onClick={toggleText}>
          Read more
        </p>
      )}
    </div>
  );
});
