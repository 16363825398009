import { createSelector } from '@reduxjs/toolkit';
import { compareDesc } from 'date-fns';

import { NotificationTypes } from 'types/enums/NotificationTypes';
import { INotification } from 'types/interfaces/Notifications';

import { RootState } from 'store/rootReducer';

const sortNotificationsByDate = (notifications: INotification[]) => {
  return [...notifications].sort((leftNotification, rightNotification) =>
    compareDesc(
      new Date(leftNotification.created_at),
      new Date(rightNotification.created_at)
    )
  );
};

const sortNotificationsByPriority = (notifications: INotification[]) => {
  const { messagesNotifications, otherNotifications } = notifications.reduce(
    (acc, notificationItem) => {
      if (notificationItem.type === NotificationTypes.Message) {
        return {
          ...acc,
          messagesNotifications: [
            notificationItem,
            ...acc.messagesNotifications,
          ],
        };
      }

      return {
        ...acc,
        otherNotifications: [notificationItem, ...acc.otherNotifications],
      };
    },
    { messagesNotifications: [], otherNotifications: [] } as {
      messagesNotifications: INotification[];
      otherNotifications: INotification[];
    }
  );

  return [
    ...sortNotificationsByDate(messagesNotifications),
    ...sortNotificationsByDate(otherNotifications),
  ];
};

const getNotificationsSelector = (state: RootState) =>
  state.notifications.notifications;

export const getSortedNotificationsSelector = createSelector(
  getNotificationsSelector,
  (notifications) => sortNotificationsByPriority(notifications)
);

export const getIsNotificationsLoadingSelector = (state: RootState) =>
  state.notifications.loading;

export const getIsNotificationsFirstLoadedSelector = (state: RootState) =>
  state.notifications.isFirstLoaded;

export const getIsUserHasUnreadNotificationsSelector = createSelector(
  getNotificationsSelector,
  (notifications) => {
    return !!notifications.find((notificationItem) => notificationItem.is_new);
  }
);
