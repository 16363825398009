import React, { memo } from 'react';
import cx from 'classnames';

import { ReactComponent as GoldIcon } from 'assets/userStatus/gold.svg';

import css from './userPaymentStatus.module.sass';

interface Props {
  spendCredits?: number | string;
  className?: string;
}

export const UserPaymentStatus: React.FC<Props> = memo(
  ({ spendCredits, className }) => {
    if (spendCredits && spendCredits === '20+') {
      return <GoldIcon className={cx(css.icon, className)} />;
    }

    return null;
  }
);
