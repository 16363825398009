import React from 'react';

import { ReactComponent as PhotoIcon } from 'assets/icons/profile-photo.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/video.svg';

import { Badge } from 'components/shared/Badge';

import css from './profileMediaCounter.module.sass';

interface Props {
  total?: number;
  current?: number;
  type?: 'photo' | 'video';
  className?: string;
}

export const ProfileMediaCounter: React.FC<Props> = ({
  total,
  current,
  type = 'photo',
  className,
}) => {
  const hasCurrent = current || current === 0;
  const hasTotal = total && total > 0;
  const hasCurrentOrTotal = Boolean(hasCurrent || hasTotal);

  const isPhoto = type === 'photo';
  const isVideo = type === 'video';

  return (
    <Badge className={className}>
      {isPhoto && <PhotoIcon className={css.icon} width="12px" height="11px" />}
      {isVideo && <VideoIcon className={css.icon} width="12px" height="12px" />}

      {hasCurrentOrTotal && (
        <span className={css.counter}>
          {hasCurrent ? `${current}/${total}` : total}
        </span>
      )}
    </Badge>
  );
};
