import { NoteType } from 'types/enums/NoteType';
import { Note } from 'types/interfaces/Note';

import { httpClient } from './httpClient';

export interface NotesResponse {
  data: Note[];
  links: {
    next: string | null;
  };
}

export const NotesApi = {
  async fetchNotes({
    noteType,
    contactId,
    next,
  }: {
    noteType: NoteType;
    contactId: string;
    next: string | null;
  }) {
    const { data: notesResponse } = await httpClient.get<NotesResponse>(
      next || '/notice',
      {
        params: {
          notice_type: noteType,
          user_ulid: contactId,
        },
      }
    );

    return notesResponse;
  },

  async fetchRealNameNote({ contactId }: { contactId: string }) {
    const { data: notesResponse } = await httpClient.get<{ data: Note }>(
      '/notice/show',
      {
        params: {
          notice_type: NoteType.RealName,
          user_ulid: contactId,
        },
      }
    );

    return notesResponse.data;
  },

  async fetchCityNote({ contactId }: { contactId: string }) {
    const { data: notesResponse } = await httpClient.get<{ data: Note }>(
      '/notice/show',
      {
        params: {
          notice_type: NoteType.City,
          user_ulid: contactId,
        },
      }
    );

    return notesResponse.data;
  },

  async createNote({
    contactId,
    noteType,
    note,
  }: {
    contactId: string;
    noteType: NoteType;
    note: string;
  }) {
    const { data } = await httpClient.post<{ data: Note }>('/notice', {
      text: note,
      user_ulid: contactId,
      notice_type: noteType,
    });

    return data;
  },

  async updateNote({ noteId, noteBody }: { noteId: number; noteBody: string }) {
    const { data } = await httpClient.put<{ data: Note }>('/notice', {
      notice_id: noteId,
      text: noteBody,
    });

    return data;
  },

  async updateNoteSort(payload: { noteId: number; order: number }[]) {
    const { data } = await httpClient.post<{ result: boolean }>(
      '/notice/sort',
      {
        data: payload.map(({ noteId, order }) => ({
          id: noteId,
          sort: order,
        })),
      }
    );

    return data;
  },
};
