import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  IcebreakerFilters,
  IcebreakersSortField,
} from 'types/enums/icebreakers/IcebreakerFilters';
import { IcebreakersCoverage } from 'types/enums/icebreakers/IcebreakersCoverage';
import { IcebreakersTab } from 'types/enums/icebreakers/IcebreakersTab';
import { Icebreaker } from 'types/interfaces/chat/Icebreaker';

interface IcebreakersState {
  icebreakers: Icebreaker[];
  icebreakersCounters: Record<IcebreakersTab, number>;
  coverageList: Record<string, IcebreakersCoverage> | null;
  isCoverageLoaded: boolean;
  nextIcebreakers: string | null;
  isLoading: boolean;
  loadingIcebreakers: (string | number)[];
  filters: IcebreakerFilters;
}

const initialState: IcebreakersState = {
  icebreakers: [],
  icebreakersCounters: {
    active: 0,
    approved: 0,
    declined: 0,
    moderation: 0,
  },
  coverageList: null,
  nextIcebreakers: null,
  isLoading: true,
  isCoverageLoaded: true,
  loadingIcebreakers: [],
  filters: {
    type: null,
    userId: null,
    tab: IcebreakersTab.Active,
    coverage: IcebreakersCoverage.All,
    order_by_field: null,
    order_by_direction: null,
  },
};

const icebreakersSlice = createSlice({
  name: 'icebreakers',
  initialState,
  reducers: {
    setIcebreakers(state, action: PayloadAction<Icebreaker[]>) {
      state.icebreakers = action.payload;
    },

    setIcebreakersCounters(
      state,
      action: PayloadAction<Partial<Record<IcebreakersTab, number>>>
    ) {
      state.icebreakersCounters = {
        ...state.icebreakersCounters,
        ...action.payload,
      };
    },

    increaseActiveIcebreakersCounter(state) {
      state.icebreakersCounters.active += 1;
    },

    increaseModerationIcebreakersCounter(state) {
      state.icebreakersCounters.moderation += 1;
    },

    decreaseActiveIcebreakersCounter(state) {
      state.icebreakersCounters.active -= 1;
    },

    setCoverageList(
      state,
      action: PayloadAction<Record<string, IcebreakersCoverage>>
    ) {
      state.isCoverageLoaded = true;
      state.coverageList = action.payload;
    },

    addIcebreaker(state, action: PayloadAction<Icebreaker>) {
      state.icebreakers = [action.payload, ...state.icebreakers];
    },

    addIcebreakers(state, action: PayloadAction<Icebreaker[]>) {
      state.icebreakers = [...action.payload, ...state.icebreakers];
    },

    removeIcebreaker(state, action: PayloadAction<number>) {
      state.icebreakers = state.icebreakers.filter(
        (icebreaker) => icebreaker.id !== action.payload
      );
    },

    setNextIcebreakers(state, action: PayloadAction<string | null>) {
      state.nextIcebreakers = action.payload;
    },

    updateIcebreaker(
      state,
      action: PayloadAction<{
        newIcebreaker: Icebreaker;
        userId: string | null;
      }>
    ) {
      const { newIcebreaker, userId } = action.payload;

      state.icebreakers = state.icebreakers.map((icebreakerItem) => {
        if (icebreakerItem.id === newIcebreaker.id) {
          return {
            ...newIcebreaker,
            user: icebreakerItem.user || newIcebreaker.user,
          };
        }

        if (
          icebreakerItem.type === newIcebreaker.type &&
          userId === icebreakerItem?.user?.ulid_id
        ) {
          return {
            ...icebreakerItem,
            is_active: 0,
            user: icebreakerItem.user || newIcebreaker.user,
          };
        }

        return icebreakerItem;
      });
    },

    setIsIcebreakersLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },

    addLoadingIcebreaker(state, action: PayloadAction<number | string>) {
      state.loadingIcebreakers = [...state.loadingIcebreakers, action.payload];
    },

    removeLoadingIcebreaker(state, action: PayloadAction<number | string>) {
      state.loadingIcebreakers = state.loadingIcebreakers.filter(
        (icebreakerId) => icebreakerId !== action.payload
      );
    },

    setIcebreakersFilters(
      state,
      action: PayloadAction<Partial<IcebreakerFilters>>
    ) {
      state.filters = { ...state.filters, ...action.payload };
    },

    setIcebreakersSortFilters(
      state,
      action: PayloadAction<IcebreakersSortField>
    ) {
      if (!action.payload) return;

      if (state.filters.order_by_field === action.payload) {
        state.filters.order_by_direction =
          state.filters.order_by_direction === 'asc' ? 'desc' : 'asc';

        return;
      }

      state.filters = {
        ...state.filters,
        order_by_field: action.payload,
        order_by_direction: 'asc',
      };
    },

    resetState() {
      return initialState;
    },
  },
});

export const {
  setIcebreakers,
  setIcebreakersCounters,
  increaseActiveIcebreakersCounter,
  increaseModerationIcebreakersCounter,
  decreaseActiveIcebreakersCounter,
  setCoverageList,
  addIcebreaker,
  addIcebreakers,
  removeIcebreaker,
  setNextIcebreakers,
  updateIcebreaker,
  setIsIcebreakersLoading,
  addLoadingIcebreaker,
  removeLoadingIcebreaker,
  setIcebreakersFilters,
  setIcebreakersSortFilters,

  resetState,
} = icebreakersSlice.actions;

export const icebreakers = icebreakersSlice.reducer;
