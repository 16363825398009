import React, { memo, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import { NoteType } from 'types/enums/NoteType';

import { NotesApi } from 'api/NotesApi';
import {
  useCityNoteQuery,
  useCityNoteQueryCache,
} from 'hooks/queries/useCityNoteQuery';
import { useValidationRules } from 'hooks/useValidationRules';

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';

import { BaseButton, ButtonTypes } from 'components/base/BaseButton';

import { TagWithIcon } from '../TagWithIcon';

import css from './userCityField.module.sass';

interface Props {
  contactId: string;
  userProfileUlid: string;
}

interface FormValues {
  city: string;
}

export const UserCityField: React.FC<Props> = memo(
  ({ contactId, userProfileUlid }) => {
    const { data: userCityNote, isLoading } = useCityNoteQuery({
      contactId,
      userId: userProfileUlid!,
    });

    const [isInputShown, setIsInputSHown] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const { register, handleSubmit } = useForm<FormValues>({
      defaultValues: { city: '' },
    });

    const { requiredValidation } = useValidationRules();
    const { updateCity } = useCityNoteQueryCache(userProfileUlid);

    const toggleInput = useCallback(() => {
      setIsInputSHown((prev) => !prev);
    }, []);

    const handleFormSubmit = useCallback(
      async (formValues: FormValues) => {
        setIsUpdating(true);

        const { data: newNote } = await NotesApi.createNote({
          contactId,
          noteType: NoteType.City,
          note: formValues.city,
        });

        setIsUpdating(false);

        updateCity(newNote);
        toggleInput();
      },
      [contactId, toggleInput, updateCity]
    );

    if (isLoading) return null;

    return (
      <div className={css.root}>
        {!isInputShown && (
          <>
            <TagWithIcon title="City">
              {userCityNote?.text || '---'}
            </TagWithIcon>
            <BaseButton
              type={ButtonTypes.Transparent}
              className={css.btn}
              onClick={toggleInput}
            >
              <EditIcon className={css.icon} />
            </BaseButton>
          </>
        )}

        {isInputShown && (
          <form onSubmit={handleSubmit(handleFormSubmit)} className={css.root}>
            <input
              ref={register(requiredValidation)}
              name="city"
              className={css.input}
              placeholder="City"
            />

            <BaseButton
              type={ButtonTypes.Transparent}
              className={css.btn}
              loading={isUpdating}
              nativeType="submit"
            >
              <SaveIcon className={css.icon} />
            </BaseButton>
          </form>
        )}
      </div>
    );
  }
);
