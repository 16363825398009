import React, { memo } from 'react';
import cx from 'classnames';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import { BaseButton, ButtonTypes } from 'components/base/BaseButton';

import css from './closeButton.module.sass';

interface Props {
  onClick: () => void;
  absolute?: boolean;
  className?: string;
}

export const CloseButton: React.FC<Props> = memo(
  ({ onClick, absolute, className }) => {
    return (
      <BaseButton
        onClick={onClick}
        type={ButtonTypes.Transparent}
        className={cx(css.root, className, { [css.absolute]: absolute })}
      >
        <CloseIcon className={css.icon} />
      </BaseButton>
    );
  }
);
