import React, { memo } from 'react';
import cx from 'classnames';

import { Spinner, SpinnerTypes } from 'components/base/Spinner';

import css from './fullscreenSpinner.module.sass';

interface Props {
  fallback?: boolean;
}

export const FullscreenSpinner: React.FC<Props> = memo(({ fallback }) => {
  return (
    <div className={cx(css.root, { [css.fallback]: fallback })}>
      <Spinner type={SpinnerTypes.Dark} size="36px" />
    </div>
  );
});
