import React, { memo } from 'react';

import { PresentStatus } from 'types/enums/presents/PresentStatus';

import { getPresentBadgeColor } from 'helpers/presents';

import css from './presentStatusBadge.module.sass';

interface Props {
  status: PresentStatus;
}

export const PresentStatusBadge: React.FC<Props> = memo(({ status }) => {
  if (status === PresentStatus.Requested)
    return (
      <div className={css.root} style={{ color: getPresentBadgeColor('info') }}>
        NEW
      </div>
    );

  if (status === PresentStatus.Refused)
    return (
      <div
        className={css.root}
        style={{ color: getPresentBadgeColor('error') }}
      >
        Refused
      </div>
    );

  if (status === PresentStatus.Insuf)
    return (
      <div
        className={css.root}
        style={{ color: getPresentBadgeColor('warning') }}
      >
        Insuff
      </div>
    );

  if (status === PresentStatus.Received)
    return (
      <div className={css.root} style={{ color: getPresentBadgeColor('info') }}>
        Received
      </div>
    );

  return (
    <div
      className={css.root}
      style={{ color: getPresentBadgeColor('success') }}
    >
      Send
    </div>
  );
});
