import React, { memo, PropsWithChildren } from 'react';
import cx from 'classnames';

import css from './container.module.sass';

interface Props {
  className?: string;
}

export const Container: React.FC<PropsWithChildren<Props>> = memo(
  ({ children, className }) => {
    return <div className={cx(css.root, className)}>{children}</div>;
  }
);
