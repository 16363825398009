import { useCallback } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import { Note } from 'types/interfaces/Note';

import { NotesApi } from 'api/NotesApi';

export const CITY_QUERY_KEY = 'city-notes-query';

export const useCityNoteQuery = (
  payload: { contactId: string; userId: string },
  queryOptions?: UseQueryOptions<Note>
) => {
  return useQuery<Note>(
    [CITY_QUERY_KEY, payload.userId],
    () =>
      NotesApi.fetchCityNote({
        contactId: payload.contactId,
      }),
    {
      cacheTime: 0,
      retry: false,
      ...queryOptions,
    }
  );
};

export const useCityNoteQueryCache = (userId: string) => {
  const queryClient = useQueryClient();

  const updateCity = useCallback(
    (newNote: Note) => {
      queryClient.setQueryData<Note>([CITY_QUERY_KEY, userId], newNote);
    },
    [userId, queryClient]
  );

  return { updateCity };
};
