import { useQuery, UseQueryOptions } from 'react-query';

import { AuthApi } from 'api/AuthApi';

export const QUERY_KEY = 'presents-profiles-query';

export const useProfilesWithPresentsQuery = (
  queryOptions?: UseQueryOptions<string[]>
) => {
  return useQuery<string[]>(QUERY_KEY, AuthApi.getProfilesWithPresents, {
    retry: false,
    ...queryOptions,
  });
};
