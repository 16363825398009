export enum MediaType {
  ProfilePublicPhoto = 1,
  ProfilePrivatePhoto = 2,
  ProfileEroticPhoto = 10,
  ProfilePrivateVideo = 3,
  ProfilePrivateLibraryPhoto = 14,
  ProfilePrivateLibraryVideo = 13,

  ConversationPhoto = 4,
  ConversationPublicPhoto = 8,
  ConversationEroticPhoto = 11,
  ConversationVideo = 5,
  ConversationPrivateLibraryPhoto = 17,
  ConversationPrivateLibraryVideo = 15,

  InMailPhoto = 6,
  InMailPublicPhoto = 9,
  InMailEroticPhoto = 12,
  InMailVideo = 7,
  InMailPrivateLibraryPhoto = 18,
  InMailPrivateLibraryVideo = 16,
}

export const PublicMediaTypes: MediaType[] = [
  MediaType.ProfilePublicPhoto,
  MediaType.ConversationPublicPhoto,
  MediaType.InMailPublicPhoto,
];
