import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getIsNotificationsFirstLoadedSelector,
  getIsNotificationsLoadingSelector,
  getSortedNotificationsSelector,
} from 'store/notifications/selectors';
import { fetchNotificationsThunk } from 'store/notifications/thunks';

import { ReactComponent as NotificationIcon } from 'assets/icons/notification.svg';

import { Spinner, SpinnerTypes } from 'components/base/Spinner';

import { BaseNotification } from '../BaseNotification';

import css from './notificationsList.module.sass';

export const NotificationsList: React.FC = memo(() => {
  const dispatch = useDispatch();

  const notifications = useSelector(getSortedNotificationsSelector);

  const isNotificationsLoading = useSelector(getIsNotificationsLoadingSelector);
  const isNotificationsFirstLoaded = useSelector(
    getIsNotificationsFirstLoadedSelector
  );

  useEffect(() => {
    if (!isNotificationsFirstLoaded) {
      dispatch(fetchNotificationsThunk());
    }
  }, [dispatch, isNotificationsFirstLoaded]);

  return (
    <div className={css.list}>
      {!isNotificationsLoading && !notifications.length && (
        <div className={css.listEmpty}>
          <NotificationIcon className={css.listEmptyIcon} />
          <p className={css.listEmptyText}>
            You don&apos;t have any activity yet!
          </p>
        </div>
      )}
      {isNotificationsLoading && (
        <div className={css.loader}>
          <Spinner type={SpinnerTypes.Dark} />
        </div>
      )}
      {!isNotificationsLoading &&
        !!notifications.length &&
        notifications.map((notificationItem) => (
          <BaseNotification
            key={notificationItem.id}
            notification={notificationItem}
          />
        ))}
    </div>
  );
});
