export const transformActivityTimeToLabel = (time: string) => {
  const dateNow = new Date();
  const dateFromLastSeenTime = new Date(time);

  const timeDiff = Math.abs(dateNow.getTime() - dateFromLastSeenTime.getTime());

  const hours = timeDiff / 1000 / 60 / 60;
  const minutes = Math.abs(
    dateNow.getMinutes() - dateFromLastSeenTime.getMinutes()
  );

  return { minutes: Math.round(minutes), hours: Math.round(hours) };
};
