import React from 'react';

import { ReactComponent as DeleteUserIcon } from 'assets/icons/profile/deleted-user.svg';

import css from './deletedUser.module.sass';

export const DeletedUser = () => {
  return (
    <div className={css.root}>
      <div className={css.textContent}>
        <DeleteUserIcon />
        <p className={css.caption}>Deleted user</p>
        <p className={css.text}>This user has been deleted</p>
      </div>
    </div>
  );
};
