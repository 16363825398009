import React from 'react';
import cx from 'classnames';

import css from './fullscreenPhoto.module.sass';

interface Props {
  src: string;
  size?: string;
  classNames?: {
    root?: string;
    img?: string;
  };
  onPhotoClick?: () => void;
}

export const FullscreenPhoto: React.FC<Props> = ({
  src,
  size,
  classNames,
  onPhotoClick,
}) => {
  return (
    <div className={cx(css.root, classNames?.root)} onClick={onPhotoClick}>
      <img
        src={src}
        alt="user"
        className={cx(css.img, classNames?.img)}
        width={size}
        height={size}
      />
    </div>
  );
};
