import { PresentStatus } from 'types/enums/presents/PresentStatus';
import { PresentCategory } from 'types/interfaces/presents/PresentCategory';
import { PresentInfo } from 'types/interfaces/presents/PresentInfo';
import { PresentRequest } from 'types/interfaces/presents/PresentRequest';
import {
  ExtendedUserDetails,
  ExtendedUserUpdatedDetails,
  UserEditOptions,
} from 'types/interfaces/user/UserDetails';
import { UserProfile } from 'types/interfaces/user/UserProfile';
import { Video } from 'types/interfaces/Video';

import { httpClient } from './httpClient';

interface UserDetailsResponse {
  data: ExtendedUserDetails;
  options: UserEditOptions;
}

interface UserEditDetailsResponse {
  data: { result: boolean };
}

export interface UserVideosResponse {
  videos: Video[];
  videos_access: number[];
}

export interface ChangeUserLanguagePayload {
  lang: string;
}

export interface ChangeUserLanguageResponse {
  site_language: string;
}

export const UserProfileApi = {
  async fetchPublicProfile(payload: { userId: string }) {
    const { data } = await httpClient.get<UserProfile>(
      `/profile/${payload.userId}`
    );

    return data;
  },

  async fetchUserDetails() {
    const { data } = await httpClient.get<UserDetailsResponse>(
      '/trusted-user/other-details'
    );

    return data;
  },

  async editUserDetails(payload: Partial<ExtendedUserUpdatedDetails>) {
    const { data } = await httpClient.put<UserEditDetailsResponse>(
      '/trusted-user/other-details',
      payload
    );

    return data;
  },

  async setMainPhoto(payload: { photoId: number }) {
    return httpClient.get(`/user/photo/set-main/${payload.photoId}`);
  },

  async deletePhoto(payload: { photoId: number }) {
    return httpClient.delete(`/user/photo/${payload.photoId}`);
  },

  async deleteVideo(payload: { videoId: number }) {
    return httpClient.delete(`/user/video/${payload.videoId}`);
  },

  async fetchUserVideos(payload: { userId: string }) {
    const { data } = await httpClient.get<UserVideosResponse>(
      `/user/${payload.userId}/videos`
    );

    return data;
  },

  async fetchUserVideo(payload: { videoId: number }) {
    const { data } = await httpClient.get<Video>(`/videos/${payload.videoId}`);

    return data;
  },

  async trackVideoSeen(payload: { videoId: number }) {
    await httpClient.post(`/user/video/${payload.videoId}/seen`);
  },

  async changeUserLanguage(payload: ChangeUserLanguagePayload) {
    const { data } = await httpClient.patch<ChangeUserLanguageResponse>(
      '/user/settings/site-language',
      {
        site_language: payload.lang,
      }
    );

    return data;
  },

  async getPresentsCategories() {
    const { data } = await httpClient.get<{ data: PresentCategory[] }>(
      '/real-gift'
    );

    return data;
  },

  async getPresentsWishlist({ userId }: { userId: string }) {
    const { data } = await httpClient.get<{ data: PresentInfo[] }>(
      `/real-gift/wishlist/${userId}`
    );

    return data;
  },

  async getPresentsRequests() {
    const { data } = await httpClient.get<{ data: PresentRequest[] }>(
      '/real-gift/processes'
    );

    return data;
  },

  async addPresentToWishlist(payload: { presentId: number; userId: string }) {
    const { data } = await httpClient.post<PresentInfo>(
      '/real-gift/add-wishlist',
      {
        ulid: payload.userId,
        real_gift_id: payload.presentId,
      }
    );

    return data;
  },

  async removePresentFromWishlist(payload: {
    presentInfoId: number;
    userId: string;
  }) {
    const { data } = await httpClient.delete<{ result: boolean }>(
      `/real-gift/remove-wishlist/${payload.presentInfoId}/${payload.userId}`
    );

    return data;
  },

  async updatePresentRequestStatus(payload: {
    presentRequestId: number;
    presentReceiverId: string;
    status: PresentStatus;
  }) {
    const { data } = await httpClient.post<{ result: boolean }>(
      `/real-gift/process/change`,
      {
        process_id: payload.presentRequestId,
        status: payload.status,
      },
      {
        headers: {
          'X-ULID': payload.presentReceiverId,
        },
      }
    );

    return data;
  },
};
