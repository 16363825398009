import React from 'react';
import cx from 'classnames';

import { MirrorService } from 'services/MirrorService';
import { Photo } from 'types/interfaces/Photo';

import css from './userProfilePhoto.module.sass';

interface Props {
  photo: Photo;
  size?: string;
  classNames?: {
    root?: string;
    img?: string;
    overlay?: string;
  };
  additionalPhotosCount?: number;
  onPhotoClick?: () => void;
}

export const UserProfilePhoto: React.FC<Props> = ({
  photo,
  size,
  classNames,
  additionalPhotosCount,
  onPhotoClick,
}) => {
  return (
    <div className={cx(css.root, classNames?.root)} onClick={onPhotoClick}>
      <div
        className={css.wrapper}
        style={{
          backgroundImage: `url("${MirrorService.resolveImagePath(
            photo.profile_url
          )}")`,
        }}
      >
        <img
          src={MirrorService.resolveImagePath(photo.profile_url)}
          alt="user"
          className={cx(css.img, classNames?.img)}
          width={size}
          height={size}
        />
        {additionalPhotosCount && (
          <div className={cx(css.overlay, classNames?.overlay)}>
            +{additionalPhotosCount}
          </div>
        )}
      </div>
    </div>
  );
};
