import React, { memo, ReactElement, ReactNode } from 'react';
import cx from 'classnames';

import styles from './tagWithIcon.module.sass';

export type PropTypes = {
  icon?: ReactElement;
  marginBottom0?: boolean;
  title: string;
  children?: ReactNode;
};

export const TagWithIcon: React.FC<PropTypes> = memo(
  ({ icon, marginBottom0 = false, title, children }) => {
    return (
      <div
        className={cx(styles.wrapper, {
          [styles.marginBottom0]: marginBottom0,
        })}
      >
        {icon && <span className={styles.icon}>{icon}</span>}
        <span className={styles.title}>{title}: </span>
        <span className={styles.bold}>{children}</span>
      </div>
    );
  }
);
