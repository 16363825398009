import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

import { MirrorService } from 'services/MirrorService';
import { NotificationTypes } from 'types/enums/NotificationTypes';
import { INotification } from 'types/interfaces/Notifications';

import { setXUlidId } from 'api/httpClient';
import { formatItemDateByDay } from 'helpers/dates';
import { getPhotoPlaceholders } from 'helpers/photoPlaceholders';
import { QueryKeys } from 'hooks/useSystemSearchQueries';
import { readNotificationThunk } from 'store/notifications/thunks';

import { ReactComponent as MatchIcon } from 'assets/icons/chat/match.svg';

import { UserAvatar } from 'components/shared/UserAvatar';
import { UserPaymentStatus } from 'components/shared/UserPaymentStatus';

import { NotificationBadge } from '../NotificationBadge';

import css from './baseNotification.module.sass';

interface Props {
  notification: INotification;
}

export const BaseNotification: React.FC<Props> = memo(({ notification }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNotificationClick = useCallback(() => {
    if (notification.receiver?.ulid_id)
      setXUlidId(notification.receiver.ulid_id);

    dispatch(readNotificationThunk({ notificationId: notification.id }));

    if (
      notification.sender?.ulid_id &&
      notification?.type === NotificationTypes.Inbox
    ) {
      navigate(
        `/mails/${notification.sender?.ulid_id}?${QueryKeys.XUlid}=${notification.receiver?.ulid_id}`
      );

      return;
    }

    if (notification.sender?.ulid_id) {
      navigate(
        `/dialogs/${notification.sender?.ulid_id}?${QueryKeys.XUlid}=${notification.receiver?.ulid_id}`
      );
    }
  }, [
    dispatch,
    navigate,
    notification.id,
    notification.receiver?.ulid_id,
    notification.sender?.ulid_id,
    notification?.type,
  ]);

  const notificationsTypesWithBadge = useMemo(
    () => [
      NotificationTypes.Inbox,
      NotificationTypes.Like,
      NotificationTypes.MutualLike,
      NotificationTypes.Message,
      NotificationTypes.ProfileNew,
      NotificationTypes.ProfilePopular,
      NotificationTypes.ProfileRecommended,
    ],
    []
  );

  const shouldShowBadge = useMemo(
    () => notificationsTypesWithBadge.includes(notification.type),
    [notification.type, notificationsTypesWithBadge]
  );

  return (
    <div
      className={cx(css.root, { [css.rootTypeNew]: notification.is_new })}
      onClick={handleNotificationClick}
    >
      <div className={css.imageWrapper}>
        <UserAvatar
          shouldShowUser
          shouldShowOnlineBadge
          url={
            MirrorService.resolveImagePath(
              notification?.sender?.main_photo?.profile_url
            ) ?? getPhotoPlaceholders(notification?.sender?.gender, 1)[0]
          }
          userUrl={
            MirrorService.resolveImagePath(
              notification?.receiver?.main_photo?.profile_url
            ) ?? getPhotoPlaceholders(notification?.sender?.gender, 1)[0]
          }
          size={50}
          className={css.image}
          badge={
            shouldShowBadge ? (
              <NotificationBadge type={notification.type} />
            ) : undefined
          }
          isOnline={!!notification?.sender?.is_online}
          isActiveUser
        />
      </div>
      <div className={css.info}>
        <div className={css.title}>
          {!!notification.connection_status && (
            <MatchIcon className={css.matchIcon} />
          )}

          <span
            className={cx({
              [css.match]: !!notification.connection_status,
            })}
          >
            {notification.title}
          </span>

          <UserPaymentStatus
            spendCredits={notification?.sender?.credits?.spend}
          />
          <p className={css.date}>
            {formatItemDateByDay(notification.created_at)}
          </p>
        </div>
        {notification.description && (
          <p className={css.description}>{notification.description}</p>
        )}
      </div>
    </div>
  );
});
