import {
  compareDesc,
  format,
  formatDistance,
  isToday,
  parseISO,
} from 'date-fns';

import { ChatDialog } from 'types/interfaces/chat/ChatDialog';

export const formatItemDateByDay = (dateString: string) => {
  if (!dateString) {
    return '';
  }

  const parsedDate = new Date(dateString);

  if (isToday(parsedDate)) {
    return format(parsedDate, 'HH:mm');
  }

  return format(parsedDate, 'MMM dd');
};

export const formatMessagesBlockDate = (dateString: string) => {
  if (!dateString) {
    return '';
  }

  const parsedDate = new Date(dateString);

  if (isToday(parsedDate)) {
    return 'Today';
  }

  return format(parsedDate, 'MMM dd');
};

export const formatMessageItemDate = (dateString: string) => {
  if (!dateString) {
    return '';
  }

  const parsedDate = new Date(dateString);

  return format(parsedDate, 'HH:mm');
};

export const formatMailItemDate = (dateString: string) => {
  if (!dateString) {
    return '';
  }

  return format(new Date(dateString), 'MMM dd, HH:mm');
};

export const formatDateAgo = (dateString?: string | null) => {
  if (!dateString) {
    return '';
  }

  const parsedDate = new Date(dateString);

  return formatDistance(parsedDate, new Date(), { addSuffix: true });
};

export const groupBySentDate = <T extends { sent_at: string }>(array: T[]) => {
  const toClusteredByDays = (acc: Record<string, T[]>, item: T) => {
    const DATE_FORMAT = 'yyyy-MM-dd';
    const date = format(parseISO(item.sent_at), DATE_FORMAT);

    if (acc[date]) {
      acc[date].push(item);
    } else {
      acc[date] = [item];
    }

    return acc;
  };

  return array.reduce(toClusteredByDays, {});
};

export const sortDialogsByDate = (dialogs: ChatDialog[]) => {
  if (!Array.isArray(dialogs)) {
    return [];
  }

  return [...dialogs].sort((a, b) => {
    const dateA = a.last_message_sent_at;
    const dateB = b.last_message_sent_at;

    return compareDesc(new Date(dateA), new Date(dateB));
  });
};

export const sortArrayBySentAtDate = <T extends { sent_at: string }>(
  array: T[]
) => {
  if (!Array.isArray(array)) {
    return [];
  }

  return [...array].sort((a, b) => {
    const dateA = a.sent_at;
    const dateB = b.sent_at;

    return compareDesc(new Date(dateA), new Date(dateB));
  });
};

export const sortArrayByCreatedAtDate = <T extends { created_at: string }>(
  array: T[]
) => {
  if (!Array.isArray(array)) {
    return [];
  }

  return [...array].sort((a, b) => {
    const dateA = a.created_at;
    const dateB = b.created_at;

    return compareDesc(new Date(dateA), new Date(dateB));
  });
};
