import React, { ReactNode } from 'react';
import cx from 'classnames';

import css from './userProfileSection.module.sass';

interface Props {
  title?: string;
  classNames?: {
    root?: string;
    title?: string;
  };
  children?: ReactNode;
}

export const UserProfileSection: React.FC<Props> = ({
  title,
  classNames,
  children,
}) => {
  return (
    <section className={cx(css.root, classNames?.root)}>
      {title && <p className={cx(css.title, classNames?.title)}>{title}</p>}
      {children}
    </section>
  );
};
