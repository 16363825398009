import { useCallback } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import { Note } from 'types/interfaces/Note';

import { NotesApi } from 'api/NotesApi';

export const REAL_NAME_QUERY_KEY = 'real-name-notes-query';

export const useRealNameNoteQuery = (
  payload: { contactId: string; userId: string },
  queryOptions?: UseQueryOptions<Note>
) => {
  return useQuery<Note>(
    [REAL_NAME_QUERY_KEY, payload.userId],
    () =>
      NotesApi.fetchRealNameNote({
        contactId: payload.contactId,
      }),
    {
      cacheTime: 0,
      retry: false,
      ...queryOptions,
    }
  );
};

export const useRealNameNoteQueryCache = (userId: string) => {
  const queryClient = useQueryClient();

  const updateRealName = useCallback(
    (newNote: Note) => {
      queryClient.setQueryData<Note>([REAL_NAME_QUERY_KEY, userId], newNote);
    },
    [userId, queryClient]
  );

  return { updateRealName };
};
