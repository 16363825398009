import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setupInterceptors } from 'api/httpInterceptors';

export const useInterceptors = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setupInterceptors({ dispatch, navigate });
  }, [dispatch, navigate]);
};
