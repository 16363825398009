import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LikeIcon } from 'assets/icons/like.svg';
import { ReactComponent as LikeOutlineIcon } from 'assets/icons/like-outline.svg';
import { ReactComponent as ProfileMessageIcon } from 'assets/icons/profile-message.svg';

import { BaseButton, ButtonTypes } from 'components/base/BaseButton';

import styles from './fullscreenControls.module.sass';

interface Props {
  contactId: string;
  isLiked: boolean;
  handleLikeClick: () => void;
}

export const FullscreenControls: React.FC<Props> = memo(
  ({ handleLikeClick, isLiked, contactId }) => {
    const { t } = useTranslation('app');

    return (
      <div className={styles.controls}>
        <BaseButton
          type={ButtonTypes.Regular}
          disabled={isLiked}
          className={styles.likeBtn}
          small
          onClick={handleLikeClick}
        >
          {isLiked ? (
            <>
              <LikeIcon fill="#DE2600" className={styles.likeIcon} />
              {t('userProfile.liked')}
            </>
          ) : (
            <>
              <LikeOutlineIcon fill="#FFFFFF" className={styles.likeIcon} />
              {t('userProfile.like')}
            </>
          )}
        </BaseButton>

        <BaseButton
          type={ButtonTypes.Accent}
          className={styles.chatBtn}
          small
          to={`/dialogs/${contactId}`}
        >
          <ProfileMessageIcon fill="white" className={styles.chatIcon} />
          {t('userProfile.chatNow')}
        </BaseButton>
      </div>
    );
  }
);
