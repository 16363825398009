import React, { PropsWithChildren } from 'react';
import cx from 'classnames';

import css from './badge.module.sass';

interface Props {
  className?: string;
}

export const Badge: React.FC<PropsWithChildren<Props>> = ({
  className,
  children,
}) => {
  return <span className={cx(css.root, className)}>{children}</span>;
};
