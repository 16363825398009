import React, { memo } from 'react';

import { useUserProfileUlid } from 'hooks/useUserProfileUlid';

import { UserProfileTab } from '../UserProfileTab';

import css from './userProfileWidget.module.sass';

export const UserProfileWidget: React.FC = memo(() => {
  const { userProfileUlid } = useUserProfileUlid();

  return (
    <div className={css.root}>
      <div className={css.content}>
        <UserProfileTab contactId={userProfileUlid} userProfileUlid={null} />
      </div>
    </div>
  );
});
