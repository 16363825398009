import React, { memo } from 'react';

import { UserProfileDetails } from 'types/interfaces/user/UserProfile';

import { isNotEmptyArray } from 'helpers/utils/isNotEmptyArray';

import { UserProfileTag } from '../UserProfileTag';

import css from './lookingForSection.module.sass';

interface Props {
  profileDetails: UserProfileDetails | null;
}

export const LookingForSection: React.FC<Props> = memo(({ profileDetails }) => {
  return (
    <div className={css.tags}>
      {isNotEmptyArray(profileDetails?.goal) &&
        profileDetails?.goal?.map((goal, i) => (
          <UserProfileTag key={i} tag={goal} />
        ))}
      {profileDetails?.people_aged_min && profileDetails?.people_aged_max && (
        <UserProfileTag
          tag={`Age: ${profileDetails?.people_aged_min} - ${profileDetails?.people_aged_max}`}
        />
      )}
      {profileDetails?.personality_type && (
        <UserProfileTag tag={profileDetails.personality_type} />
      )}
    </div>
  );
});
