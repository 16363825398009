import React from 'react';
import { Route, Routes } from 'react-router-dom';
import loadable from '@loadable/component';

import { Pages } from 'types/enums/Pages';
import { RoutesRestrictionType } from 'types/enums/RoutesRestrictionType';

import { useMedia } from 'hooks/useMedia';

import { AppLayout } from 'components/shared/AppLayout';
import { AppRoute } from 'components/shared/AppRoute';

const EditProfilePage = loadable(
  () =>
    import(/* webpackChunkName: "EditProfilePage" */ 'pages/EditProfilePage'),
  {
    resolveComponent: (component) => component.EditProfilePage,
  }
);

const Faq = loadable(
  () =>
    import(/* webpackChunkName: "Faq" */ 'components/sections/MyProfile/Faq'),
  {
    resolveComponent: (component) => component.Faq,
  }
);

const SettingsList = loadable(
  () =>
    import(
      /* webpackChunkName: "SettingsList" */ 'components/sections/MyProfile/Settings/SettingsList'
    ),
  {
    resolveComponent: (component) => component.SettingsList,
  }
);

const NotificationSettings = loadable(
  () =>
    import(
      /* webpackChunkName: "NotificationSettings" */ 'components/sections/MyProfile/Settings/Notifications'
    ),
  {
    resolveComponent: (component) => component.Notifications,
  }
);

const ContactUs = loadable(
  () =>
    import(
      /* webpackChunkName: "ContactUs" */ 'components/sections/MyProfile/ContactUs'
    ),
  {
    resolveComponent: (component) => component.ContactUs,
  }
);

const NotFoundPage = loadable(
  () => import(/* webpackChunkName: "NotFoundPage" */ 'pages/NotFoundPage'),
  {
    resolveComponent: (component) => component.NotFoundPage,
  }
);

const ChatPage = loadable(
  () => import(/* webpackChunkName: "ChatPage" */ 'pages/ChatPage'),
  {
    resolveComponent: (component) => component.ChatPage,
  }
);

const IcebreakersPage = loadable(
  () =>
    import(/* webpackChunkName: "IcebreakersPage" */ 'pages/IcebreakersPage'),
  {
    resolveComponent: (component) => component.IcebreakersPage,
  }
);

const LegalPage = loadable(
  () => import(/* webpackChunkName: "LegalPage" */ 'pages/LegalPage'),
  {
    resolveComponent: (component) => component.LegalPage,
  }
);

const UserProfilePage = loadable(
  () =>
    import(/* webpackChunkName: "UserProfilePage" */ 'pages/UserProfilePage'),
  {
    resolveComponent: (component) => component.UserProfilePage,
  }
);

const FinderPage = loadable(
  () => import(/* webpackChunkName: "FinderPage" */ 'pages/FinderPage'),
  {
    resolveComponent: (component) => component.FinderPage,
  }
);

const MessengerPage = loadable(
  () => import(/* webpackChunkName: "MessengerPage" */ 'pages/MessengerPage'),
  {
    resolveComponent: (component) => component.MessengerPage,
  }
);

const PresentsPage = loadable(
  () => import(/* webpackChunkName: "PresentsPage" */ 'pages/PresentsPage'),
  {
    resolveComponent: (component) => component.PresentsPage,
  }
);

const PresentsStatisticPage = loadable(
  () =>
    import(
      /* webpackChunkName: "PresentsStatisticPage" */ 'pages/PresentsStatisticPage'
    ),
  {
    resolveComponent: (component) => component.PresentsStatisticPage,
  }
);

const MailsChainsPage = loadable(
  () =>
    import(/* webpackChunkName: "MailsChatsPage" */ 'pages/MailsChainsPage'),
  {
    resolveComponent: (component) => component.MailsChatsPage,
  }
);

const MailsChatPage = loadable(
  () => import(/* webpackChunkName: "MailsChatPage" */ 'pages/MailsChatPage'),
  {
    resolveComponent: (component) => component.MailsChatPage,
  }
);

const MyProfilePage = loadable(
  () => import(/* webpackChunkName: "MyProfilePage" */ 'pages/MyProfilePage'),
  {
    resolveComponent: (component) => component.MyProfilePage,
  }
);

const LoginPage = loadable(
  () => import(/* webpackChunkName: "LoginPage" */ 'pages/LoginPage'),
  {
    resolveComponent: (component) => component.LoginPage,
  }
);

const IndexPage = loadable(
  () => import(/* webpackChunkName: "IndexPage" */ 'pages/IndexPage'),
  {
    resolveComponent: (component) => component.IndexPage,
  }
);

export const AppRouter = () => {
  const { isMobile } = useMedia();

  return (
    <Routes>
      <Route
        path={Pages.Login}
        element={<AppRoute component={LoginPage} isPublic />}
      />

      <Route
        path={Pages.Home}
        element={<AppRoute component={IndexPage} isPublic />}
      />

      <Route element={<AppLayout />}>
        <Route
          path={Pages.Finder}
          element={<AppRoute component={FinderPage} isPrivate />}
        />
        <Route
          path={Pages.Dialogs}
          element={<AppRoute component={MessengerPage} isPrivate />}
        />
        <Route
          path={Pages.DialogsTread}
          element={
            <AppRoute
              component={isMobile ? ChatPage : MessengerPage}
              isPrivate
            />
          }
        />
        <Route
          path={Pages.Mails}
          element={<AppRoute component={MailsChainsPage} isPrivate />}
        />
        <Route
          path={Pages.MailsTread}
          element={<AppRoute component={MailsChatPage} isPrivate />}
        />
        <Route
          path={Pages.Presents}
          element={<AppRoute component={PresentsPage} isPrivate />}
        />
        <Route
          path={Pages.PresentsStatistic}
          element={
            <AppRoute
              component={PresentsStatisticPage}
              restrictedType={RoutesRestrictionType.MobileRestricted}
              isPrivate
            />
          }
        />
        <Route
          path={Pages.MyProfile}
          element={<AppRoute component={MyProfilePage} isPrivate />}
        />
        <Route
          path={Pages.ContactUs}
          element={<AppRoute component={ContactUs} isPrivate />}
        />
        <Route
          path={Pages.Edit}
          element={<AppRoute component={EditProfilePage} isPrivate />}
        />
        <Route
          path={Pages.Settings}
          element={<AppRoute component={SettingsList} isPrivate />}
        />
        <Route
          path={Pages.Notifications}
          element={<AppRoute component={NotificationSettings} isPrivate />}
        />
        <Route
          path={Pages.Icebreakers}
          element={
            <AppRoute
              component={IcebreakersPage}
              restrictedType={RoutesRestrictionType.MobileRestricted}
              isPrivate
            />
          }
        />
        <Route
          path={Pages.UserProfile}
          element={<AppRoute component={UserProfilePage} isPrivate />}
        />
        <Route
          path={Pages.FAQ}
          element={<AppRoute component={Faq} isPrivate />}
        />
      </Route>

      <Route
        path={Pages.TrustedPolicy}
        element={<AppRoute component={LegalPage} isPrivate />}
      />

      <Route element={<AppRoute component={LegalPage} />}>
        <Route path={Pages.AntiScamPolicy} element={null} />
        <Route path={Pages.ComplianceStatement} element={null} />
        <Route path={Pages.DisclosuresDisclaimers} element={null} />
        <Route path={Pages.PrivacyPolicy} element={null} />
        <Route path={Pages.TrackingPolicy} element={null} />
        <Route path={Pages.Terms} element={null} />
      </Route>

      <Route
        path={Pages.NotFound}
        element={<AppRoute component={NotFoundPage} />}
      />
    </Routes>
  );
};
