import React, { useCallback, useMemo, useState } from 'react';
import cx from 'classnames';

import { MirrorService } from 'services/MirrorService';
import { Photo } from 'types/interfaces/Photo';

import { FullscreenPhotosViewer } from 'components/shared/FullscreenPhotosViewer';

import { UserProfilePhoto } from '../UserProfilePhoto';

import css from './userProfilePhotoGallery.module.sass';

interface Props {
  isControlsVisible?: boolean;
  photos: Photo[];
}

export const UserProfilePhotoGallery: React.FC<Props> = ({
  photos,
  isControlsVisible = true,
}) => {
  const [fullscreenPhoto, setFullscreenPhoto] = useState<Photo | null>(null);

  const maxPhotoCount = useMemo(() => 6, []);

  const showedPhotos = useMemo(
    () =>
      photos.length > maxPhotoCount ? photos.slice(0, maxPhotoCount) : photos,
    [maxPhotoCount, photos]
  );
  const additionalPhotosCount = useMemo(
    () => photos.length - showedPhotos.length,
    [photos.length, showedPhotos.length]
  );
  const fullScreenPhotos = useMemo(
    () =>
      photos.map((photo) => ({
        id: photo.id,
        url: MirrorService.resolveImagePath(photo.big_url),
      })),
    [photos]
  );
  const isOneRowGallery = useMemo(() => photos.length <= 3, [photos.length]);

  const handlePhotoClick = useCallback((photo: Photo) => {
    if (photo) {
      setFullscreenPhoto(photo);
    }
  }, []);

  return (
    <>
      <ul className={css.galleryList}>
        {showedPhotos.map((photo, i) => (
          <li
            key={photo.id}
            className={cx(css.galleryListItem, {
              [css.galleryListItemTwoRow]: isOneRowGallery,
            })}
          >
            <UserProfilePhoto
              classNames={{
                root: css.galleryBox,
              }}
              photo={photo}
              onPhotoClick={() => handlePhotoClick(photo)}
              additionalPhotosCount={
                i === maxPhotoCount - 1 && additionalPhotosCount
                  ? additionalPhotosCount
                  : undefined
              }
            />
          </li>
        ))}
      </ul>
      {!!fullscreenPhoto && (
        <FullscreenPhotosViewer
          photos={fullScreenPhotos}
          activePhoto={{
            id: fullscreenPhoto.id,
            url: MirrorService.resolveImagePath(fullscreenPhoto.big_url),
          }}
          onClose={() => setFullscreenPhoto(null)}
          isControlsVisible={isControlsVisible}
        />
      )}
    </>
  );
};
