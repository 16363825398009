import React, { memo } from 'react';
import cx from 'classnames';
import EmojiPicker, {
  EmojiClickData,
  SuggestionMode,
} from 'emoji-picker-react';

import { PopupInline } from '../../../shared/PopupInline';

import css from './emojiPickerPopup.module.sass';

interface Props {
  rootClassName?: string;
  onSelect: (emojiData: EmojiClickData) => void;
  onClose: () => void;
}

export const EmojiPickerPopup: React.FC<Props> = memo(
  ({ rootClassName, onSelect, onClose }) => {
    return (
      <PopupInline
        onClose={onClose}
        bodyClassName={cx(css.root, rootClassName)}
      >
        <EmojiPicker
          emojiVersion="5.0"
          lazyLoadEmojis
          width="100%"
          previewConfig={{
            showPreview: false,
          }}
          onEmojiClick={onSelect}
          searchDisabled
          suggestedEmojisMode={SuggestionMode.RECENT}
        />
      </PopupInline>
    );
  }
);
