import React, { memo, PropsWithChildren, ReactElement } from 'react';
import cx from 'classnames';

import { BaseButton, ButtonTypes } from 'components/base/BaseButton';

import css from './iconBtn.module.sass';

interface Props {
  text: string;
  icon: ReactElement;
  disabled?: boolean;
  onClick?: () => void;
}

export const IconBtn: React.FC<PropsWithChildren<Props>> = memo(
  ({ text, icon, disabled, onClick }) => {
    if (!onClick) {
      return (
        <div className={cx(css.root, { [css.disabled]: disabled })}>
          {icon}
          <span className={css.text}>{text}</span>
        </div>
      );
    }

    return (
      <BaseButton
        type={ButtonTypes.Transparent}
        onClick={onClick}
        disabled={disabled}
        className={cx(css.root, { [css.disabled]: disabled })}
      >
        {icon}
        <span className={css.text}>{text}</span>
      </BaseButton>
    );
  }
);
