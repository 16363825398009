import React, { memo, useMemo } from 'react';

import css from './userProfileWizardInfo.module.sass';

interface Props {
  wizardInfo?: Record<string, string>;
}

export const UserProfileWizardInfo: React.FC<Props> = memo(({ wizardInfo }) => {
  const formattedInfo = useMemo(
    () => Object.entries(wizardInfo || {}),
    [wizardInfo]
  );

  return (
    <div className={css.root}>
      {formattedInfo.map(([question, answer], index) => (
        <div className={css.item} key={index}>
          <p className={css.question}>{question}</p>
          <p className={css.answer}>{answer}</p>
        </div>
      ))}
    </div>
  );
});
