import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export enum ValidationsRules {
  Required = 'requiredValidation',
  Age = 'ageValidation',
  AgeRequired = 'ageRequiredValidation',
  Email = 'emailValidation',
  EmailRequired = 'emailRequiredValidation',
  Password = 'passwordValidation',
  PasswordRequired = 'passwordRequiredValidation',
  Username = 'usernameValidation',
  UsernameRequired = 'usernameRequiredValidation',
}

export const useValidationRules = () => {
  const { t } = useTranslation(['common', 'app']);

  const requiredValidation = useMemo(
    () => ({
      required: {
        value: true,
        message: t('common:validations.requiredField'),
      },
    }),
    [t]
  );

  const ageValidation = {
    min: {
      value: 18,
      message: t('common:validations.ageMin'),
    },
    max: {
      value: 100,
      message: t('common:validations.ageMax'),
    },
  };

  const ageRequiredValidation = {
    ...requiredValidation,
    ...ageValidation,
  };

  const emailValidation = {
    pattern: {
      value: /.*/,
      message: t('common:validations.email'),
    },
  };

  const emailRequiredValidation = {
    ...requiredValidation,
    ...emailValidation,
  };

  const passwordValidation = {
    minLength: {
      value: 8,
      message: t('common:validations.passwordMin'),
    },
    maxLength: {
      value: 40,
      message: t('common:validations.passwordMax'),
    },
    pattern: {
      value: /^[\S]*$/,
      message: t('common:validations.passwordInvalid'),
    },
  };

  const passwordRequiredValidation = {
    ...requiredValidation,
    ...passwordValidation,
  };

  const usernameValidation = {
    maxLength: {
      value: 30,
      message: t('common:validations.usernameMax'),
    },
    minLength: {
      value: 2,
      message: t('common:validations.usernameMin'),
    },
    pattern: {
      value: /^[\x21-\x7E]*$/,
      message: t('common:validations.usernameMax'),
    },
  };

  const usernameRequiredValidation = {
    ...requiredValidation,
    ...usernameValidation,
  };

  return {
    [ValidationsRules.Required]: requiredValidation,
    [ValidationsRules.Email]: emailValidation,
    [ValidationsRules.EmailRequired]: emailRequiredValidation,
    [ValidationsRules.Password]: passwordValidation,
    [ValidationsRules.PasswordRequired]: passwordRequiredValidation,
    [ValidationsRules.Age]: ageValidation,
    [ValidationsRules.AgeRequired]: ageRequiredValidation,
    [ValidationsRules.Username]: usernameValidation,
    [ValidationsRules.UsernameRequired]: usernameRequiredValidation,
  };
};
