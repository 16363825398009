import React, { memo, PropsWithChildren } from 'react';

import { ChatInfoWidget } from 'components/sections/Messenger/ChatInfoWidget';
import { NotificationsWidget } from 'components/sections/Notifications/NotificationsWidget';
import { PresentsWidget } from 'components/sections/PresentsWidget';
import { UserProfileWidget } from 'components/sections/UserProfile/UserProfileWidget';
import { Container } from 'components/shared/Container';
import { PrivatePageDesktopNavigation } from 'components/shared/PrivatePageDesktopNavigation';

import css from './desktopPageLayout.module.sass';

interface Props {
  showChatInfo?: boolean;
  showUserInfo?: boolean;
  showPresentsInfo?: boolean;
  hideSidebar?: boolean;
}

export const DesktopPageLayout: React.FC<PropsWithChildren<Props>> = memo(
  ({ showChatInfo, showUserInfo, showPresentsInfo, hideSidebar, children }) => {
    return (
      <div className={css.root}>
        <PrivatePageDesktopNavigation />

        {hideSidebar && (
          <Container className={css.oneColumContainer}>
            <div className={css.oneColumContent}>{children}</div>
          </Container>
        )}
        {!hideSidebar && (
          <>
            <Container className={css.primaryContainer}>
              <div className={css.primaryContent}>{children}</div>
            </Container>

            <div className={css.sidebar}>
              <Container className={css.sidebarContainer}>
                <div className={css.sidebarContent}>
                  {showChatInfo && <ChatInfoWidget />}
                  {showUserInfo && <UserProfileWidget />}
                  {showPresentsInfo && <PresentsWidget />}

                  <NotificationsWidget
                    isExpandable={
                      showChatInfo || showUserInfo || showPresentsInfo
                    }
                  />
                </div>
              </Container>
            </div>
          </>
        )}
      </div>
    );
  }
);
