import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';

import { MirrorService } from 'services/MirrorService';

import { setXUlidId } from 'api/httpClient';
import { getProfilesListInfo, Profile } from 'helpers/profiles';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { updateUser } from 'store/auth/authSlice';

import fadeTransition from 'styles/transitions/fade.module.sass';

import { Portal } from '../Portal';
import { UserAvatar } from '../UserAvatar';

import css from './profileListPopup.module.sass';

interface ProfileInfo {
  value: string;
  label: string;
  image: string;
}

interface Props {
  source: string;
  isMobile: boolean;
  customProfiles?: Profile[];
  onClose: () => void;
}

export const ProfileListPopup: React.FC<Props> = memo(
  ({ isMobile, customProfiles, source, onClose }) => {
    const dispatch = useDispatch();
    const [isLoaded, setIsLoaded] = useState(false);

    const outsideClickRef = useOutsideClick(
      () => isLoaded && onClose && onClose()
    );

    const handleProfileChange = useCallback(
      (profile: ProfileInfo) => {
        setXUlidId(profile.value);
        dispatch(
          updateUser({
            ulid_id: profile.value,
            photo_url: profile.image,
            name: profile.label,
          })
        );
        onClose();
      },
      [dispatch, onClose]
    );

    const profiles = useMemo(
      () =>
        (
          (customProfiles?.length && customProfiles) ||
          getProfilesListInfo() ||
          []
        ).map((profile) => ({
          label: profile.name,
          value: profile.external_id,
          image: profile.image_url,
        })),
      [customProfiles]
    );

    useEffect(() => {
      setIsLoaded(true);
    }, []);

    return (
      <Portal>
        <div
          className={cx(css.root, {
            [css.mobile]: isMobile,
            [css.desktop]: !isMobile,
          })}
          ref={outsideClickRef}
        >
          <CSSTransition in appear timeout={200} classNames={fadeTransition}>
            <ul className={css.profileList}>
              {profiles.length &&
                profiles.map((profile) => (
                  <li key={profile.value} className={css.profileItem}>
                    <NavLink
                      to={`${source}?x_ulid=${profile.value}`}
                      className={css.profileLink}
                      onClick={() => handleProfileChange(profile)}
                    >
                      <UserAvatar
                        isActiveUser
                        url={MirrorService.resolveImagePath(profile.image)}
                        size={36}
                      />
                      <p className={css.name}>{profile.label}</p>
                    </NavLink>
                  </li>
                ))}
            </ul>
          </CSSTransition>
        </div>
      </Portal>
    );
  }
);
