import { useCallback } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import { UserProfile } from 'types/interfaces/user/UserProfile';

import { UserProfileApi } from 'api/UserProfileApi';

export const BASE_USER_PROFILE_QUERY_KEY = 'user-profile-query';
const TIME = 60 * 1000 * 5;

export const getUserProfileQueryKey = (userId: string) => {
  return `${BASE_USER_PROFILE_QUERY_KEY}-${userId}`;
};

export const useUserProfileQuery = (
  userId: string,
  queryOptions?: UseQueryOptions<UserProfile, any>
) => {
  const queryClient = useQueryClient();

  const cachedData = queryClient.getQueryData<UserProfile>(
    getUserProfileQueryKey(userId)
  );

  return useQuery<UserProfile>(
    getUserProfileQueryKey(userId),
    () => UserProfileApi.fetchPublicProfile({ userId }),
    {
      retry: false,
      staleTime: TIME,
      cacheTime: TIME,
      initialData: cachedData,
      ...queryOptions,
    }
  );
};

export const useUserProfileQueryCache = (userId: string) => {
  const queryClient = useQueryClient();

  const updateProfile = useCallback(
    (payload: UserProfile) => {
      queryClient.setQueryData(getUserProfileQueryKey(userId), payload);
    },
    [queryClient, userId]
  );

  return { updateProfile };
};

export const useInvalidateUserProfileQuery = (userId?: string) => {
  const queryClient = useQueryClient();

  const makeCacheInvalid = useCallback(() => {
    if (userId) {
      queryClient.invalidateQueries(getUserProfileQueryKey(userId));
    }
  }, [queryClient, userId]);

  return { makeCacheInvalid };
};
