import React, { memo } from 'react';

import { UserProfile } from 'types/interfaces/user/UserProfile';

import { isNotEmptyArray } from 'helpers/utils/isNotEmptyArray';

import { OPTIONS_ICON_MAP } from 'components/sections/UserProfile/UserProfileAboutTags/icons';

import { TagWithIcon } from '../TagWithIcon';

interface Props {
  css?: any;
  userProfile: UserProfile;
}

export const UserProfileAboutTags: React.FC<Props> = memo(
  ({ userProfile, css }) => {
    return (
      <ul className={css.tagList}>
        {userProfile.date_of_birth && (
          <li className={css.tagListItem}>
            <TagWithIcon icon={OPTIONS_ICON_MAP.date_of_birth} title="Birthday">
              {new Date(userProfile.date_of_birth).toLocaleDateString('en-us', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </TagWithIcon>
          </li>
        )}
        {userProfile?.country?.name && (
          <li>
            <TagWithIcon icon={OPTIONS_ICON_MAP.country} title="Country">
              {userProfile.country?.name}
            </TagWithIcon>
          </li>
        )}
        {userProfile?.details?.body_type && (
          <li>
            <TagWithIcon icon={OPTIONS_ICON_MAP.body_type} title="Body Type">
              {userProfile.details.body_type}
            </TagWithIcon>
          </li>
        )}
        {userProfile?.details?.marital_status && (
          <li>
            <TagWithIcon
              icon={OPTIONS_ICON_MAP.marital_status}
              title="Relationship"
            >
              {userProfile.details.marital_status}
            </TagWithIcon>
          </li>
        )}
        {userProfile?.details?.occupation && (
          <li>
            <TagWithIcon icon={OPTIONS_ICON_MAP.occupation} title="Occupation">
              {userProfile.details.occupation}
            </TagWithIcon>
          </li>
        )}
        {userProfile?.details?.english_level && (
          <li>
            <TagWithIcon
              icon={OPTIONS_ICON_MAP.english_level}
              title="English level"
            >
              {userProfile.details.english_level}
            </TagWithIcon>
          </li>
        )}
        {userProfile?.details?.other_languages &&
          isNotEmptyArray(userProfile.details.other_languages) && (
            <li>
              <TagWithIcon
                icon={OPTIONS_ICON_MAP.other_languages}
                title="Languages"
                marginBottom0
              >
                {userProfile.details.other_languages.join(', ')}
              </TagWithIcon>
            </li>
          )}
      </ul>
    );
  }
);
