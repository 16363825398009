import React, { memo } from 'react';

import { PopupMobile } from 'components/shared/PopupMobile';

import { NotificationsList } from '../NotificationsList';

import css from './notificationsPopup.module.sass';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const NotificationsPopup: React.FC<Props> = memo(
  ({ isOpen, onClose }) => {
    return (
      <PopupMobile bodyClassName={css.root} onClose={onClose} isOpen={isOpen}>
        <NotificationsList />
      </PopupMobile>
    );
  }
);
