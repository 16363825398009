import React from 'react';

import { UserDetailsKey } from 'types/enums/UserDetailsKey';

import { ReactComponent as BirthdayIcon } from 'assets/profileIcons/about/birthday.svg';
import { ReactComponent as BodyTypeIcon } from 'assets/profileIcons/about/body-type.svg';
import { ReactComponent as CountryIcon } from 'assets/profileIcons/about/country.svg';
import { ReactComponent as DrinkingIcon } from 'assets/profileIcons/about/drink2.svg';
import { ReactComponent as EducationIcon } from 'assets/profileIcons/about/education2.svg';
import { ReactComponent as EnglishLevelIcon } from 'assets/profileIcons/about/english-level.svg';
import { ReactComponent as HeightIcon } from 'assets/profileIcons/about/height2.svg';
import { ReactComponent as LanguageIcon } from 'assets/profileIcons/about/languages.svg';
import { ReactComponent as OccupationIcon } from 'assets/profileIcons/about/occupation.svg';
import { ReactComponent as RelationshipIcon } from 'assets/profileIcons/about/relationship.svg';
import { ReactComponent as ReligionIcon } from 'assets/profileIcons/about/religion2.svg';
import { ReactComponent as SmokingIcon } from 'assets/profileIcons/about/smoking2.svg';
import { ReactComponent as WeightIcon } from 'assets/profileIcons/about/weight2.svg';

export const OPTIONS_ICON_MAP: Record<string, React.ReactElement> = {
  [UserDetailsKey.BodyType]: <BodyTypeIcon fill="#808080" />,
  [UserDetailsKey.Drink]: <DrinkingIcon fill="#808080" />,
  [UserDetailsKey.Education]: <EducationIcon fill="#808080" />,
  date_of_birth: <BirthdayIcon fill="#808080" />,
  country: <CountryIcon fill="#808080" />,
  [UserDetailsKey.EnglishLevel]: <EnglishLevelIcon fill="#808080" />,
  [UserDetailsKey.Height]: <HeightIcon fill="#808080" />,
  [UserDetailsKey.OtherLanguages]: <LanguageIcon fill="#808080" />,
  [UserDetailsKey.MaritalStatus]: <RelationshipIcon />,
  [UserDetailsKey.Religion]: <ReligionIcon fill="#808080" />,
  [UserDetailsKey.Smoking]: <SmokingIcon fill="#808080" />,
  [UserDetailsKey.Weight]: <WeightIcon fill="#808080" />,
  [UserDetailsKey.Occupation]: <OccupationIcon fill="#808080" />,
};
