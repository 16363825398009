import { AppDispatch } from 'store';

import { LikePayload, SympathiesApi } from 'api/SympathiesApi';
import { setCommonToastError } from 'store/common/commonSlice';
import { likeUser } from 'store/finder/finderSlice';
import { likeMailsChainContact } from 'store/mails/mailsSlice';
import { likeChatContact } from 'store/messenger/messengerSlice';

export const likeUserProfileThunk =
  (payload: LikePayload) => async (dispatch: AppDispatch) => {
    dispatch(likeUser({ userId: payload.receiverId, isLiked: true }));
    dispatch(
      likeChatContact({
        userId: payload.userId,
        contactId: payload.receiverId,
        isLiked: true,
      })
    );
    dispatch(
      likeMailsChainContact({
        userId: payload.userId,
        contactId: payload.receiverId,
        isLiked: true,
      })
    );

    try {
      await SympathiesApi.like(payload);
    } catch (error: any) {
      dispatch(
        setCommonToastError(
          error?.response?.data?.receiver_id?.[0] ||
            "Oops, contact wasn't liked!"
        )
      );

      dispatch(likeUser({ userId: payload.receiverId, isLiked: false }));
      dispatch(
        likeChatContact({
          userId: payload.userId,
          contactId: payload.receiverId,
          isLiked: false,
        })
      );
      dispatch(
        likeMailsChainContact({
          userId: payload.userId,
          contactId: payload.receiverId,
          isLiked: false,
        })
      );
    }
  };
