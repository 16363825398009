import React, { memo, useCallback, useState } from 'react';
import cx from 'classnames';

import { NoteType } from 'types/enums/NoteType';

import { useChatUsers } from 'hooks/messenger/useChatUsers';

import { UserProfileTab } from 'components/sections/UserProfile/UserProfileTab';

import { NotesTab } from './submodules/NotesTab';

import css from './chatInfoWidget.module.sass';

enum ChatInfoTabs {
  ContactProfile = 1,
  MyProfile = 2,
  MyNotes = 3,
  ContactNotes = 4,
}

export const ChatInfoWidget: React.FC = memo(() => {
  const { contactId, userProfileUlid } = useChatUsers();

  const [selectedTab, setSelectedTab] = useState(ChatInfoTabs.ContactProfile);

  const handleTabChange = useCallback(
    (tab: ChatInfoTabs) => {
      if (!contactId) return;

      setSelectedTab(tab);
    },
    [contactId]
  );

  return (
    <div className={css.root}>
      <div className={css.header}>
        <div
          className={css.headerItem}
          onClick={() => {
            handleTabChange(ChatInfoTabs.ContactProfile);
          }}
        >
          Contact
        </div>
        <div
          className={css.headerItem}
          onClick={() => {
            handleTabChange(ChatInfoTabs.ContactNotes);
          }}
        >
          Contact notes
        </div>
        <div
          className={css.headerItem}
          onClick={() => {
            handleTabChange(ChatInfoTabs.MyProfile);
          }}
        >
          My profile
        </div>
        <div
          className={css.headerItem}
          onClick={() => {
            handleTabChange(ChatInfoTabs.MyNotes);
          }}
        >
          My notes
        </div>
      </div>

      <div className={css.headerTrack}>
        <div
          className={cx(css.headerActiveTrack, {
            [css.headerActiveTrack2]: selectedTab === ChatInfoTabs.ContactNotes,
            [css.headerActiveTrack3]: selectedTab === ChatInfoTabs.MyProfile,
            [css.headerActiveTrack4]: selectedTab === ChatInfoTabs.MyNotes,
          })}
        />
      </div>

      <div className={css.content} id="notesScrollContainer">
        {selectedTab === ChatInfoTabs.ContactProfile && (
          <UserProfileTab
            contactId={contactId}
            userProfileUlid={userProfileUlid}
          />
        )}
        {selectedTab === ChatInfoTabs.MyProfile && (
          <UserProfileTab contactId={userProfileUlid} userProfileUlid={null} />
        )}
        {selectedTab === ChatInfoTabs.MyNotes && (
          <NotesTab
            contactId={contactId}
            userProfileUlid={userProfileUlid}
            noteType={NoteType.MyNotes}
          />
        )}
        {selectedTab === ChatInfoTabs.ContactNotes && (
          <NotesTab
            contactId={contactId}
            userProfileUlid={userProfileUlid}
            noteType={NoteType.ContactNotes}
          />
        )}
      </div>
    </div>
  );
});
