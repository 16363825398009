import React, { PropsWithChildren, ReactNode } from 'react';

import { DesktopSpinner } from '../DesktopSpinner';

type PropTypes = {
  loading: boolean;
  loader?: ReactNode;
};

export const LoadableContent: React.FC<PropsWithChildren<PropTypes>> = ({
  loading,
  loader,
  children,
}) => {
  return (
    <>
      {loading && (loader || <DesktopSpinner />)}
      {!loading && children}
    </>
  );
};
