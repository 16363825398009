import React, { memo, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

import { getProfilesListInfo } from 'helpers/profiles';
import { useProfilesWithPresentsQuery } from 'hooks/queries/useProfilesWithPresentsQuery';
import {
  getIsEnabledPresentsSelector,
  getNewDialogsCount,
  getNewMailsChainsCount,
  getPresentsRequestsCount,
} from 'store/common/selectors';

import { ReactComponent as FindIcon } from 'assets/icons/find.svg';
import { ReactComponent as IcebreakersIcon } from 'assets/icons/icebreakers.svg';
import { ReactComponent as MailsIcon } from 'assets/icons/mail.svg';
import { ReactComponent as MessageIcon } from 'assets/icons/message.svg';
import { ReactComponent as PresentsIcon } from 'assets/icons/presents.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg';

import { BaseButton, ButtonTypes } from 'components/base/BaseButton';

import { Container } from '../Container';
import { Logo } from '../Logo';
import { ProfileListPopup } from '../ProfileListPopup';

import css from './privatePageDesktopNavigation.module.sass';

export const PrivatePageDesktopNavigation: React.FC = memo(() => {
  const { data: profilesWithPresentsData } = useProfilesWithPresentsQuery();

  const newDialogsCount = useSelector(getNewDialogsCount);
  const newMailsCount = useSelector(getNewMailsChainsCount);
  const isEnabledPresents = useSelector(getIsEnabledPresentsSelector);
  const presentsRequestsCount = useSelector(getPresentsRequestsCount);

  const [selectedProfileSource, setSelectedProfileSource] = useState<
    string | null
  >(null);

  const closeProfileSelect = useCallback(() => {
    setSelectedProfileSource(null);
  }, []);

  const openPresentsSelect = useCallback(() => {
    setSelectedProfileSource('/presents');
  }, []);

  const openMyProfileSelect = useCallback(() => {
    setSelectedProfileSource('/my-profile');
  }, []);

  const profiles = useMemo(() => getProfilesListInfo(), []);

  const profilesWithPresents = useMemo(
    () =>
      profiles?.filter(
        (profileItem) =>
          profilesWithPresentsData?.includes(profileItem.external_id)
      ),
    [profiles, profilesWithPresentsData]
  );

  return (
    <nav className={css.root}>
      <Container className={css.container}>
        <Logo dark />

        <div className={css.navLinksLeft}>
          <NavLink
            to="/finder"
            className={({ isActive }) =>
              cx(css.navLinkLeft, { [css.navLinkActive]: isActive })
            }
          >
            <FindIcon className={css.navLinkLeftIcon} />
            <span>Finder</span>
          </NavLink>
          <NavLink
            to="/dialogs"
            className={({ isActive }) =>
              cx(css.navLinkLeft, { [css.navLinkActive]: isActive })
            }
          >
            <MessageIcon className={css.navLinkLeftIcon} />
            <span>Messages</span>
            {!!newDialogsCount && (
              <div className={css.rectangleLeftLink}>
                {newDialogsCount > 99 ? '99+' : newDialogsCount}
              </div>
            )}
          </NavLink>
          <NavLink
            to="/mails"
            className={({ isActive }) =>
              cx(css.navLinkLeft, { [css.navLinkActive]: isActive })
            }
          >
            <MailsIcon className={css.navLinkLeftIcon} />
            <span>Mails</span>
            {!!newMailsCount && (
              <div className={css.rectangleLeftLink}>
                {newMailsCount > 99 ? '99+' : newMailsCount}
              </div>
            )}
          </NavLink>
          <NavLink
            to="/icebreakers"
            className={({ isActive }) =>
              cx(css.navLinkLeft, { [css.navLinkActive]: isActive })
            }
          >
            <IcebreakersIcon className={css.navLinkLeftIcon} />
            <span>Icebreakers</span>
          </NavLink>

          {!!profilesWithPresents?.length && (
            <BaseButton
              className={css.navLinkLeft}
              type={ButtonTypes.Transparent}
              onClick={openPresentsSelect}
            >
              <PresentsIcon className={css.navLinkLeftIcon} />
              <span>Presents</span>

              {!!presentsRequestsCount && (
                <div className={css.rectangleLeftLink}>
                  {presentsRequestsCount > 99 ? '99+' : presentsRequestsCount}
                </div>
              )}
            </BaseButton>
          )}

          {isEnabledPresents && !profilesWithPresents?.length && (
            <NavLink
              to="/presents"
              className={({ isActive }) =>
                cx(css.navLinkLeft, { [css.navLinkActive]: isActive })
              }
            >
              <PresentsIcon className={css.navLinkLeftIcon} />
              <span>Presents</span>

              {!!presentsRequestsCount && (
                <div className={css.rectangleLeftLink}>
                  {presentsRequestsCount > 99 ? '99+' : presentsRequestsCount}
                </div>
              )}
            </NavLink>
          )}
        </div>

        <div className={css.divider} />

        <div className={css.navLinksRight}>
          {profiles?.length ? (
            <BaseButton
              type={ButtonTypes.Transparent}
              onClick={openMyProfileSelect}
            >
              <ProfileIcon className={css.navLinkRightIcon} />
            </BaseButton>
          ) : (
            <NavLink
              to="/my-profile"
              className={({ isActive }) =>
                cx(css.navLinkRight, { [css.navLinkActive]: isActive })
              }
            >
              <ProfileIcon className={css.navLinkRightIcon} />
            </NavLink>
          )}

          {selectedProfileSource && (
            <ProfileListPopup
              isMobile={false}
              onClose={closeProfileSelect}
              source={selectedProfileSource}
              customProfiles={
                selectedProfileSource === '/presents'
                  ? profilesWithPresents
                  : []
              }
            />
          )}
        </div>
      </Container>
    </nav>
  );
});
