import { getSessionStorageItem } from './sessionStorage';

export interface Profile {
  ulid: string;
  name: string;
  email: string;
  external_id: string;
  image_url: string;
}

export const getProfilesListInfo = (): Profile[] | null => {
  try {
    const profiles = JSON.parse(
      getSessionStorageItem('profilesInfo') || ''
    ) as Profile[];

    return profiles.sort((leftProfile, rightProfile) => {
      return leftProfile.name.localeCompare(rightProfile.name);
    });
  } catch (error) {
    return null;
  }
};

export const getIsEnabledUserAvatar = () =>
  getSessionStorageItem('isEnabledUserAvatar');
