import React, { memo, PropsWithChildren, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';

import { useOutsideClick } from 'hooks/useOutsideClick';
import { usePreventBgScroll } from 'hooks/usePreventBgScroll';

import fadeTransition from 'styles/transitions/fade.module.sass';

import css from './popupInline.module.sass';

interface Props {
  bodyClassName?: string;
  zIndex?: number;
  left?: string;
  bottom?: string;
  maxWidth?: string;
  onClose: () => void;
}

export const PopupInline: React.FC<PropsWithChildren<Props>> = memo(
  ({
    bodyClassName,
    onClose,
    children,
    zIndex = 12,
    bottom = '100%',
    left = '0px',
    maxWidth,
  }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const outsideClickRef = useOutsideClick(
      () => isLoaded && onClose && onClose()
    );

    usePreventBgScroll({ enabled: true });

    // ? needed to activate hook only when popup is opened
    useEffect(() => {
      setIsLoaded(true);

      return () => setIsLoaded(false);
    }, []);

    return (
      <>
        <CSSTransition in appear timeout={200} classNames={fadeTransition}>
          <div
            ref={outsideClickRef}
            className={cx(css.popupContent, bodyClassName)}
            style={{
              zIndex: zIndex + 1,
              bottom,
              left,
              maxWidth,
            }}
          >
            {children}
          </div>
        </CSSTransition>
      </>
    );
  }
);
