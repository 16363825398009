import React from 'react';

import { getRandomItem } from 'helpers/array';
import { COLOR_TAGS_BACKGROUNDS } from 'helpers/constants';

import css from './userProfileTag.module.sass';

type PropTypes = {
  tag: string | number;
  withRandomColor?: boolean;
};

export const UserProfileTag: React.FC<PropTypes> = ({
  tag,
  withRandomColor,
}) => {
  if (withRandomColor) {
    return (
      <p
        className={css.tag}
        style={{ background: getRandomItem(COLOR_TAGS_BACKGROUNDS) }}
      >
        {tag}
      </p>
    );
  }

  return <p className={css.tag}>{tag}</p>;
};
