import React from 'react';
import classNames from 'classnames';

import { Spinner, SpinnerTypes } from 'components/base/Spinner';

import css from './desktopSpinner.module.sass';

interface Props {
  className?: string;
}

export const DesktopSpinner: React.FC<Props> = ({ className }) => {
  return (
    <div className={classNames(css.root, className)}>
      <Spinner type={SpinnerTypes.Dark} size="36px" />
    </div>
  );
};
