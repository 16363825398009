import { ReactElement } from 'react';
import { useMediaQuery } from 'react-responsive';

type PropTypes = {
  children: ReactElement;
};

export const Mobile: React.FC<PropTypes> = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return isMobile ? children : null;
};

export const Default: React.FC<PropTypes> = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });

  return isNotMobile ? children : null;
};
