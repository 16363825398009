export enum UserDetailsKey {
  AboutMe = 'about_me',
  BodyType = 'body_type',
  ColorOfEyes = 'color_of_eyes',
  ColorOfHair = 'color_of_hair',
  CountChildren = 'count_children',
  Drink = 'drinking',
  Education = 'education',
  EnglishLevel = 'english_level',
  Goal = 'goal',
  Height = 'height',
  Hobbies = 'hobbies',
  MaritalStatus = 'marital_status',
  Occupation = 'occupation',
  OtherLanguages = 'other_languages',
  PeopleAgedMax = 'people_aged_max',
  PeopleAgedMin = 'people_aged_min',
  PersonalityType = 'personality_type',
  Religion = 'religion',
  Smoking = 'smoking',
  Traits = 'traits',
  Weight = 'weight',
  PartnersAge = 'partners_age',
}
