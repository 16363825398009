export const getPresentBadgeColor = (
  type: 'error' | 'info' | 'success' | 'default' | 'warning'
) => {
  if (type === 'error') return 'var(--danger, #F00)';
  if (type === 'info') return 'var(--accent, #00A9D0)';
  if (type === 'success') return '#2ABD39';
  if (type === 'warning') return '#bdb82a';

  return 'var(--text-primary, #222)';
};
