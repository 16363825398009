import { MirrorService } from 'services/MirrorService';
import { MediaType } from 'types/enums/MediaType';
import { ModerationMediaType } from 'types/enums/ModerationMediaType';
import { ModerationStatus } from 'types/enums/ModerationStatus';
import { ModerationPhoto } from 'types/interfaces/ModerationPhoto';
import { Photo } from 'types/interfaces/Photo';
import { Video } from 'types/interfaces/Video';

import { httpClient } from './httpClient';

const moderationMediaTypeToMediaType: Record<ModerationMediaType, MediaType> = {
  [ModerationMediaType.PublicPhoto]: MediaType.ProfilePublicPhoto,
  [ModerationMediaType.PrivatePhoto]: MediaType.ProfilePrivatePhoto,
  [ModerationMediaType.PrivateLibraryPhoto]:
    MediaType.ProfilePrivateLibraryPhoto,
  [ModerationMediaType.Video]: MediaType.ProfilePrivateVideo,
  [ModerationMediaType.PrivateLibraryVideo]:
    MediaType.ProfilePrivateLibraryVideo,
  [ModerationMediaType.Document]: MediaType.ProfilePrivateLibraryPhoto,
};

export interface UploadMediaPayload {
  file: File;
}

export interface UploadTUMediaPayload {
  file: File;
  moderationMediaType: ModerationMediaType;
  mediaType: MediaType;
}

export interface UploadConversationMediaPayload {
  file: File;
  contactId: string;
}

export interface CopyFromGalleryPayload {
  catalog_media_id: number;
  contact_id: string;
}

export const MediaApi = {
  async getUserMediaPhotos({ contactId }: { contactId: string | undefined }) {
    const { data } = await httpClient.get<{ photos: Photo[] }>('/user/photo', {
      params: {
        contact_id: contactId,
      },
    });

    return data.photos;
  },

  async uploadPublicUserPhoto(payload: UploadMediaPayload) {
    const formData = new FormData();
    formData.append('photo', payload.file);

    const { data } = await httpClient.post<Photo>(
      '/user/photo/upload',
      formData
    );

    return data;
  },

  async uploadPrivateUserPhoto(payload: UploadMediaPayload) {
    const formData = new FormData();
    formData.append('photo', payload.file);

    const { data } = await httpClient.post<Photo>(
      '/user/private-photo/upload',
      formData
    );

    return data;
  },

  async uploadPrivateVideo(payload: UploadMediaPayload) {
    const formData = new FormData();
    formData.append('video', payload.file);

    const { data } = await httpClient.post<Video>(
      '/user/video/upload',
      formData
    );

    return data;
  },

  async uploadConversationPhoto(payload: UploadConversationMediaPayload) {
    const formData = new FormData();
    formData.append('photo', payload.file);
    formData.append('contact_id', payload.contactId);

    return httpClient.post<Photo>('/conversation/photo/upload', formData);
  },

  async uploadConversationVideo(payload: UploadConversationMediaPayload) {
    const formData = new FormData();
    formData.append('video', payload.file);
    formData.append('contact_id', payload.contactId);

    return httpClient.post<Photo>('/conversation/video/upload', formData);
  },

  async copyFromGalleryToInMails(payload: CopyFromGalleryPayload) {
    return httpClient.post<Photo>('/inmail/media/from-catalog', payload);
  },

  async copyFromGalleryToChat(payload: CopyFromGalleryPayload) {
    return httpClient.post<Photo>('/conversation/send-from-catalog', payload);
  },

  async getUserModerationMediaPhotos(): Promise<Photo[]> {
    const { data } = await httpClient.get<{ data: ModerationPhoto[] }>(
      '/trusted-user/media/moderation'
    );

    return (data?.data || []).map((photoItem) => ({
      id: photoItem.id,
      is_main: false,
      moderationType: photoItem.type,
      media_type: moderationMediaTypeToMediaType[photoItem.type],
      moderation_status: ModerationStatus.Process,
      big_url: MirrorService.resolveImagePath(photoItem.file_url),
      profile_url: MirrorService.resolveImagePath(photoItem.preview_url),
    }));
  },

  async getUserDeclinedMediaPhotos(): Promise<Photo[]> {
    const { data } = await httpClient.get<{ data: ModerationPhoto[] }>(
      '/trusted-user/media/decline'
    );

    return (data?.data || []).map((photoItem) => ({
      id: photoItem.id,
      is_main: false,
      media_type: moderationMediaTypeToMediaType[photoItem.type],
      moderation_status: ModerationStatus.Deleted,
      big_url: MirrorService.resolveImagePath(photoItem.file_url),
      profile_url: MirrorService.resolveImagePath(photoItem.preview_url),
      moderationType: photoItem.type,
    }));
  },

  async uploadPhotoMedia(payload: UploadTUMediaPayload): Promise<Photo> {
    const formData = new FormData();
    formData.append('file', payload.file);
    formData.append('type', String(payload.moderationMediaType));

    const { data: moderationPhoto } = await httpClient.post<{
      data: ModerationPhoto;
    }>('/trusted-user/media/upload', formData);

    return {
      id: moderationPhoto.data?.id,
      is_main: false,
      media_type: payload.mediaType,
      moderation_status: ModerationStatus.Process,
      big_url: MirrorService.resolveImagePath(moderationPhoto.data?.file_url),
      profile_url: MirrorService.resolveImagePath(
        moderationPhoto.data?.preview_url
      ),
    };
  },

  async getMediaAccesses(contactId: string) {
    return httpClient.post<{
      accesses: Record<MediaType, string[]>;
      mass_accesses: MediaType[];
    }>(`/media/accesses/${contactId}`);
  },

  async getSentMedia(contactId: string) {
    return httpClient.get<{
      inmails: number[];
      messages: number[];
    }>(`/conversation/get-sent-media/${contactId}`);
  },
};
