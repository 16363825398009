import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { MirrorService } from 'services/MirrorService';
import { PresentStatus } from 'types/enums/presents/PresentStatus';
import { PresentRequest } from 'types/interfaces/presents/PresentRequest';

import { updatePresentRequestStatusThunk } from 'store/profile/thunks';

import { ReactComponent as TrackIcon } from 'assets/icons/presents-track.svg';

import { BaseButton, ButtonTypes } from 'components/base/BaseButton';
import { CloseButton } from 'components/shared/CloseButton';
import { Popup } from 'components/shared/Popup';
import { UserAvatar } from 'components/shared/UserAvatar';

import css from './presentConfirmPopup.module.sass';

interface Props {
  presentRequest: PresentRequest;
  handleClose: () => void;
}

export const PresentConfirmPopup: React.FC<Props> = ({
  presentRequest,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const [isAccepting, setIsAccepting] = useState(false);
  const [isDeclining, setIsDeclining] = useState(false);

  const handleAccept = useCallback(async () => {
    if (!presentRequest.id || !presentRequest.receiver?.ulid_id) return;

    setIsAccepting(true);

    await dispatch(
      updatePresentRequestStatusThunk({
        status: PresentStatus.Sent,
        presentRequestId: presentRequest.id,
        presentReceiverId: presentRequest.receiver?.ulid_id,
      })
    );

    setIsAccepting(false);
    handleClose();
  }, [
    dispatch,
    handleClose,
    presentRequest.id,
    presentRequest.receiver?.ulid_id,
  ]);

  const handleDecline = useCallback(async () => {
    if (!presentRequest.id || !presentRequest.receiver?.ulid_id) return;

    setIsDeclining(true);

    await dispatch(
      updatePresentRequestStatusThunk({
        status: PresentStatus.Refused,
        presentRequestId: presentRequest.id,
        presentReceiverId: presentRequest.receiver?.ulid_id,
      })
    );

    setIsDeclining(false);
    handleClose();
  }, [
    dispatch,
    handleClose,
    presentRequest.id,
    presentRequest.receiver?.ulid_id,
  ]);

  return (
    <Popup popupClassName={css.root}>
      <div className={css.content}>
        <CloseButton onClick={handleClose} absolute />

        <h3 className={css.title}>
          {presentRequest.sender.name} wants to send you a present
        </h3>

        <div className={css.track}>
          <div className={css.avatar}>
            <UserAvatar
              size={75}
              url={MirrorService.resolveImagePath(
                presentRequest.sender.main_photo?.profile_url
              )}
              isActiveUser
            />
          </div>
          <TrackIcon />
          <div className={css.avatar}>
            <UserAvatar
              size={75}
              url={MirrorService.resolveImagePath(
                presentRequest.receiver.main_photo?.profile_url
              )}
              isActiveUser
            />
          </div>
        </div>

        <div className={css.presentInfo}>
          <img
            className={css.presentImg}
            src={MirrorService.resolveImagePath(
              presentRequest.gift.gift_image_file
            )}
            alt="present"
          />
          <p className={css.presentName}>{presentRequest.gift.name}</p>
        </div>

        {/* <p className={css.deliveryDate}>
          Estimated arrival time: <b>June 17, 2023</b>
        </p> */}

        <div className={css.btns}>
          <BaseButton
            small
            type={ButtonTypes.OutlinePrimary}
            className={css.declineBtn}
            onClick={handleDecline}
            loading={isDeclining}
            disabled={isDeclining || isAccepting}
          >
            Decline Delivery
          </BaseButton>
          <BaseButton
            small
            className={css.confirmBtn}
            onClick={handleAccept}
            loading={isAccepting}
            disabled={isDeclining || isAccepting}
          >
            Confirm Delivery
          </BaseButton>
        </div>
      </div>
    </Popup>
  );
};
