import React, { memo } from 'react';

import { isNotEmptyArray } from 'helpers/utils/isNotEmptyArray';

import { UserProfileSection } from '../UserProfileSection';
import { UserProfileTag } from '../UserProfileTag';

import css from './hobbiesAndTraitsSection.module.sass';

interface Props {
  traits?: string[];
  hobbies?: string[];
  labelStyles?: {
    root?: string;
    title?: string;
  };
}

export const HobbiesAndTraitsSection: React.FC<Props> = memo(
  ({ traits, hobbies, labelStyles }) => {
    return (
      <>
        {isNotEmptyArray(traits) && (
          <UserProfileSection title="Traits" classNames={labelStyles}>
            <div className={css.tags}>
              {traits?.map((trait, i) => (
                <UserProfileTag key={i} tag={trait} withRandomColor />
              ))}
            </div>
          </UserProfileSection>
        )}
        {isNotEmptyArray(hobbies) && (
          <UserProfileSection title="Hobbies" classNames={labelStyles}>
            <div className={css.tags}>
              {hobbies?.map((hobby, i) => (
                <UserProfileTag key={i} tag={hobby} withRandomColor />
              ))}
            </div>
          </UserProfileSection>
        )}
      </>
    );
  }
);
