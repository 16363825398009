import React, { memo } from 'react';
import cx from 'classnames';

import { NotificationTypes } from 'types/enums/NotificationTypes';

import { ReactComponent as PopularIcon } from 'assets/notifications/fire.svg';
import { ReactComponent as InboxIcon } from 'assets/notifications/inbox.svg';
import { ReactComponent as LikeIcon } from 'assets/notifications/like.svg';
import { ReactComponent as MessageIcon } from 'assets/notifications/message.svg';
import { ReactComponent as NewProfileIcon } from 'assets/notifications/new-profile.svg';

import css from './notificationBadge.module.sass';

interface Props {
  type: NotificationTypes;
}

export const NotificationBadge: React.FC<Props> = memo(({ type }) => {
  if (type === NotificationTypes.Inbox)
    return (
      <p className={cx(css.root, css.rootTypeInbox)}>
        <InboxIcon />
      </p>
    );

  if (type === NotificationTypes.Like || type === NotificationTypes.MutualLike)
    return (
      <p className={cx(css.root, css.rootTypeLike)}>
        <LikeIcon />
      </p>
    );

  if (type === NotificationTypes.Message)
    return (
      <p className={cx(css.root, css.rootTypeMessage)}>
        <MessageIcon />
      </p>
    );

  if (type === NotificationTypes.ProfileNew)
    return (
      <p className={cx(css.root, css.rootTypeNewProfile)}>
        <NewProfileIcon />
      </p>
    );

  if (
    type === NotificationTypes.ProfilePopular ||
    type === NotificationTypes.ProfileRecommended
  )
    return (
      <p className={cx(css.root, css.rootTypePopular)}>
        <PopularIcon />
      </p>
    );

  return null;
});
