import React, { useCallback, useEffect } from 'react';
import { Message } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';
import cx from 'classnames';

import styles from './baseTextarea.module.sass';

type PropTypes = {
  value?: string;
  maxRows?: number;
  minRows?: number;
  name: string;
  placeholder?: string;
  maxSymbols?: number;
  isSymbolsCounterHidden?: boolean;
  className?: string;
  rootClassName?: string;
  disabled?: boolean;
  error?: string | Message;
  onHeightChange?: (height: number) => void;
  onChange?: (newValue: string) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

export const BaseTextarea = React.forwardRef<HTMLTextAreaElement, PropTypes>(
  (
    {
      value,
      maxRows,
      minRows,
      name,
      placeholder,
      maxSymbols,
      isSymbolsCounterHidden = false,
      className,
      rootClassName,
      disabled,
      error,
      onHeightChange,
      onChange,
      onKeyPress,
      onFocus,
      onBlur,
    },
    ref
  ) => {
    const symbolsLeftTextShown = maxSymbols && !isSymbolsCounterHidden;

    const inputHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value: newValue } = event.target;

      if (!maxSymbols) {
        onChange && onChange(newValue);

        return;
      }

      if (
        value &&
        value.length === maxSymbols &&
        newValue.length >= value.length
      ) {
        return;
      }

      onChange && onChange(newValue);
    };

    const renderSymbols = useCallback(() => {
      if (!symbolsLeftTextShown) return null;

      return (
        <p className={styles.symbolMax}>{maxSymbols - (value?.length || 0)}</p>
      );
    }, [maxSymbols, symbolsLeftTextShown, value?.length]);

    useEffect(() => {
      if (value && value?.length >= (maxSymbols || 0)) {
        onChange && onChange(value.slice(0, maxSymbols));
      }
    }, [maxSymbols, onChange, value]);

    return (
      <div className={cx(styles.root, rootClassName)}>
        <TextareaAutosize
          value={value}
          name={name}
          placeholder={placeholder}
          maxRows={maxRows}
          minRows={minRows}
          ref={ref}
          className={cx(styles.textarea, className, {
            [styles.textareaDisabled]: disabled,
            [styles.textareaWithCounter]: !isSymbolsCounterHidden,
          })}
          disabled={disabled}
          onHeightChange={onHeightChange}
          onChange={inputHandler}
          onKeyPress={onKeyPress}
          onFocus={onFocus}
          onBlur={onBlur}
        />

        {symbolsLeftTextShown && (
          <div className={styles.symbols}>{renderSymbols()} </div>
        )}

        {error && (
          <div
            className={cx(styles.errorText, {
              [styles.noSymbolsLeftText]: !symbolsLeftTextShown,
            })}
          >
            {error}
          </div>
        )}
      </div>
    );
  }
);
