import { UserCard } from 'types/interfaces/user/UserCard';

import { httpClient } from './httpClient';

export enum ActionTypes {
  Like = 1,
}

export type LikePayload = {
  receiverId: string;
  userId: string;
  sourceType: SourceTypes;
  actionType: ActionTypes;
};

export enum SourceTypes {
  Finder = 1,
  UserProfile = 2,
  Generic = 3,
  Discovery = 4,
  Likes = 5,
  Visits = 7,
  Video = 8,
}

export interface MyLikesPopupResponse {
  likes: UserCard[];
}

export const SympathiesApi = {
  async like(payload: LikePayload) {
    const {
      receiverId,
      actionType = ActionTypes.Like,
      sourceType = SourceTypes.UserProfile,
    } = payload;

    await httpClient.post('/sympathies', {
      receiver_id: receiverId,
      action_type: actionType,
      source_type: sourceType,
    });
  },
};
