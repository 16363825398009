import { useCallback } from 'react';

import { useEventListener } from 'hooks/useEventListener';

export const useEscButton = (eventHandler: () => void) => {
  const handleKeyDown = useCallback(
    (event: any) => {
      if (event.keyCode === 27) {
        eventHandler();
      }
    },
    [eventHandler]
  );

  useEventListener(document, 'keydown', handleKeyDown);
};
