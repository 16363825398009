import React, { memo, useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { EmojiClickData } from 'emoji-picker-react';

import { NoteType } from 'types/enums/NoteType';
import { Note } from 'types/interfaces/Note';

import { NotesApi } from 'api/NotesApi';
import { useValidationRules } from 'hooks/useValidationRules';

import { ReactComponent as EmojiIcon } from 'assets/icons/emoji.svg';

import { BaseButton, ButtonTypes } from 'components/base/BaseButton';
import { BaseTextarea } from 'components/base/BaseTextarea';
import { EmojiPickerPopup } from 'components/sections/Messenger/EmojiPickerPopup';
import { IconBtn } from 'components/shared/IconBtn';
import { Popup } from 'components/shared/Popup';

import css from './createNotePopup.module.sass';

const MIN_NOTE_SYMBOLS = 5;
const MAX_NOTE_SYMBOLS = 250;

interface Props {
  contactId: string;
  noteType: NoteType;
  onClose: () => void;
  onSubmit: (newNote: Note) => void;
}

interface FormValues {
  note: string;
}

export const CreateNotePopup: React.FC<Props> = memo(
  ({ contactId, noteType, onSubmit, onClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);

    const { requiredValidation } = useValidationRules();

    const noteValidation = useMemo(
      () => ({
        minLength: {
          value: MIN_NOTE_SYMBOLS,
          message: `Note should contain at least ${MIN_NOTE_SYMBOLS} symbols`,
        },
        maxLength: {
          value: MAX_NOTE_SYMBOLS,
          message: 'Note should not contain  more then 250 symbols',
        },
      }),
      []
    );

    const { handleSubmit, control, errors, setValue, getValues } =
      useForm<FormValues>({
        defaultValues: {
          note: '',
        },
      });

    const onFormSubmit = useCallback(
      async (formValues: FormValues) => {
        setIsLoading(true);

        const { data: newNote } = await NotesApi.createNote({
          contactId,
          note: formValues.note,
          noteType,
        });

        onSubmit(newNote);

        setIsLoading(false);
        onClose();
      },
      [contactId, noteType, onClose, onSubmit]
    );

    const toggleEmojiPicker = useCallback(() => {
      setIsEmojiPickerOpen((prev) => !prev);
    }, []);

    const hideEmojiPicker = useCallback(() => setIsEmojiPickerOpen(false), []);

    const handleInsertEmoji = useCallback(
      (emojiData: EmojiClickData) => {
        setValue('note', `${getValues('note')}${emojiData.emoji}`);

        hideEmojiPicker();
      },
      [getValues, hideEmojiPicker, setValue]
    );

    return (
      <Popup
        onClose={onClose}
        popupClassName={css.popup}
        bodyClassName={css.popupBody}
      >
        <div className={css.root}>
          <h3 className={css.title}>New note</h3>

          <form onSubmit={handleSubmit(onFormSubmit)} className={css.form}>
            <div className={css.textareaContainer}>
              <Controller
                as={BaseTextarea}
                name="note"
                control={control}
                minRows={1}
                maxRows={10}
                maxSymbols={MAX_NOTE_SYMBOLS}
                placeholder="Type your note..."
                rules={{ ...requiredValidation, ...noteValidation }}
                error={errors.note?.message}
                className={css.textarea}
              />
            </div>
            <div className={css.actionsContainer}>
              {isEmojiPickerOpen && (
                <EmojiPickerPopup
                  rootClassName={css.assetPopup}
                  onSelect={handleInsertEmoji}
                  onClose={hideEmojiPicker}
                />
              )}

              <div className={css.actionBtns}>
                <IconBtn
                  text="Emojis"
                  icon={<EmojiIcon />}
                  onClick={toggleEmojiPicker}
                />
              </div>
            </div>

            <BaseButton
              type={ButtonTypes.Accent}
              className={css.btn}
              nativeType="submit"
              loading={isLoading}
            >
              Create Note
            </BaseButton>
          </form>
        </div>
      </Popup>
    );
  }
);
