import React, { memo, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import cx from 'classnames';

import { Note } from 'types/interfaces/Note';

import { NotesApi } from 'api/NotesApi';
import { formatItemDateByDay } from 'helpers/dates';

import { ReactComponent as EditIcon } from 'assets/icons/profile/edit-field.svg';
import { ReactComponent as ArrowIcon } from 'assets/profileIcons/nav-back.svg';

import { BaseButton, ButtonTypes } from 'components/base/BaseButton';
import { BaseTextarea } from 'components/base/BaseTextarea';

import css from './noteItem.module.sass';

const MIN_NOTE_SYMBOLS = 5;
const MAX_NOTE_SYMBOLS = 250;

interface FormValues {
  note: string;
}

interface Props {
  isFirst: boolean;
  isLast: boolean;
  prevNoteItemId: number | null;
  nextNoteItemId: number | null;
  noteItem: Note;
  updateNote: (updatedNoteId: number, updatedNote: Partial<Note>) => void;
}

export const NoteItem: React.FC<Props> = memo(
  ({
    isFirst,
    isLast,
    noteItem,
    prevNoteItemId,
    nextNoteItemId,
    updateNote,
  }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const { control, errors, handleSubmit, setValue } = useForm<FormValues>({
      defaultValues: { note: '' },
    });

    const toggleEdit = () => {
      setIsEditing((prev) => !prev);
    };

    const noteValidation = useMemo(
      () => ({
        required: {
          value: true,
          message: 'Note is required',
        },
        minLength: {
          value: MIN_NOTE_SYMBOLS,
          message: `Note should contain at least ${MIN_NOTE_SYMBOLS} symbols`,
        },
        maxLength: {
          value: MAX_NOTE_SYMBOLS,
          message: 'Note should not contain  more then 250 symbols',
        },
      }),
      []
    );

    const handleEdit = async (newValues: FormValues) => {
      setIsUpdating(true);

      try {
        const { data: newNote } = await NotesApi.updateNote({
          noteId: noteItem.id,
          noteBody: newValues.note,
        });

        updateNote(noteItem.id, {
          text: newNote.text,
        });

        setIsUpdating(false);
      } finally {
        setIsUpdating(false);
        setIsEditing(false);
      }
    };

    const handleNoteSortIncrease = async () => {
      setIsUpdating(true);

      try {
        const updatedNotes = [
          {
            noteId: noteItem.id,
            order: (noteItem.sort || 0) + 1,
          },
        ];

        if (nextNoteItemId) {
          updatedNotes.push({
            noteId: nextNoteItemId,
            order: noteItem.sort || 0,
          });
        }

        const { result } = await NotesApi.updateNoteSort(updatedNotes);

        if (result) {
          updateNote(noteItem.id, {
            sort: (noteItem.sort || 0) + 1,
          });

          if (nextNoteItemId)
            updateNote(nextNoteItemId, {
              sort: noteItem.sort || 0,
            });
        }

        setIsUpdating(false);
      } finally {
        setIsUpdating(false);
      }
    };

    const handleNoteSortDecrease = async () => {
      setIsUpdating(true);

      try {
        const updatedNotes = [
          {
            noteId: noteItem.id,
            order: (noteItem.sort || 0) - 1,
          },
        ];

        if (prevNoteItemId) {
          updatedNotes.push({
            noteId: prevNoteItemId,
            order: noteItem.sort || 0,
          });
        }

        const { result } = await NotesApi.updateNoteSort(updatedNotes);

        if (result) {
          updateNote(noteItem.id, {
            sort: (noteItem.sort || 0) - 1,
          });

          if (prevNoteItemId)
            updateNote(prevNoteItemId, {
              sort: noteItem.sort || 0,
            });
        }

        setIsUpdating(false);
      } finally {
        setIsUpdating(false);
      }
    };

    useEffect(() => {
      if (isEditing) setValue('note', noteItem.text);
    }, [isEditing, noteItem.text, setValue]);

    return (
      <div className={css.root}>
        <div className={css.arrows}>
          <BaseButton
            className={css.arrowBtn}
            onClick={handleNoteSortIncrease}
            type={ButtonTypes.Transparent}
            disabled={isFirst || isUpdating}
          >
            <ArrowIcon className={cx(css.icon, css.arrowUp)} />
          </BaseButton>
          <BaseButton
            className={css.arrowBtn}
            onClick={handleNoteSortDecrease}
            type={ButtonTypes.Transparent}
            disabled={isLast || isUpdating}
          >
            <ArrowIcon className={cx(css.icon, css.arrowDown)} />
          </BaseButton>
        </div>

        <div className={css.container}>
          <div className={css.header}>
            <p className={css.title}>
              {!!noteItem?.auth_code && noteItem.auth_code}
            </p>
            <p className={css.date}>
              {formatItemDateByDay(noteItem.created_at)}
            </p>
          </div>

          {!isEditing && (
            <div className={css.noteContainer}>
              <p className={css.note}>{noteItem.text}</p>
              <BaseButton
                onClick={toggleEdit}
                type={ButtonTypes.Transparent}
                disabled={isUpdating}
              >
                <EditIcon className={css.icon} />
              </BaseButton>
            </div>
          )}

          {isEditing && (
            <form className={css.form} onSubmit={handleSubmit(handleEdit)}>
              <Controller
                as={BaseTextarea}
                control={control}
                isSymbolsCounterHidden
                name="note"
                placeholder="Your note"
                minRows={2}
                maxRows={5}
                maxSymbols={MAX_NOTE_SYMBOLS}
                rules={noteValidation}
                className={css.noteInput}
                error={errors.note?.message as string}
              />
              <BaseButton
                nativeType="submit"
                type={ButtonTypes.Transparent}
                loading={isUpdating}
                loaderSize="20px"
              >
                <EditIcon className={css.icon} />
              </BaseButton>
            </form>
          )}
        </div>
      </div>
    );
  }
);
