import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import { useParams } from 'react-router-dom';

import { ActionTypes, SourceTypes } from 'api/SympathiesApi';
import {
  useUserProfileQuery,
  useUserProfileQueryCache,
} from 'hooks/queries/useUserProfileQuery';
import { useEscButton } from 'hooks/useEscButton';
import { useMedia } from 'hooks/useMedia';
import { usePreventBgScroll } from 'hooks/usePreventBgScroll';
import { useUserProfileUlid } from 'hooks/useUserProfileUlid';
import { likeUserProfileThunk } from 'store/sympathies/thunks';

import { ReactComponent as CarouselNextIcon } from 'assets/icons/carousel-next.svg';
import { ReactComponent as CarouselPrevIcon } from 'assets/icons/carousel-prev.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import { BaseButton, ButtonTypes } from 'components/base/BaseButton';
import { ProfileMediaCounter } from 'components/shared/ProfileMediaCounter';

import { FullscreenControls } from './submodules/FullscreenControls';
import { FullscreenPhoto } from './submodules/FullscreenPhoto';

import css from './fullscreenPhotosViewer.module.sass';

interface Photo {
  id: number;
  url: string;
}

interface Props {
  photos: Photo[];
  activePhoto: Photo;
  isCounterExists?: boolean;
  isControlsVisible?: boolean;
  onClose: () => void;
}

export const FullscreenPhotosViewer: React.FC<Props> = ({
  photos,
  activePhoto,
  isCounterExists = true,
  isControlsVisible = true,
  onClose,
}) => {
  const { isMobile } = useMedia();

  const dispatch = useDispatch();

  const { userId = '' } = useParams<{ userId: string }>();

  const { userProfileUlid } = useUserProfileUlid();

  const { data: userProfile } = useUserProfileQuery(userId, {
    enabled: !!userId,
  });

  const { updateProfile } = useUserProfileQueryCache(
    userProfile?.ulid_id || ''
  );
  const isNotOwner = useMemo(
    () => !!userProfile && userProfileUlid !== userProfile.ulid_id,
    [userProfile, userProfileUlid]
  );

  const handleLikeClick = () => {
    if (!userProfile?.liked && userProfile?.ulid_id) {
      dispatch(
        likeUserProfileThunk({
          userId: userProfileUlid,
          receiverId: userId,
          actionType: ActionTypes.Like,
          sourceType: SourceTypes.UserProfile,
        })
      );

      updateProfile({ ...userProfile, liked: true });
    }
  };

  useEscButton(onClose);

  usePreventBgScroll({ enabled: true });

  return (
    <div className={css.root} onClick={onClose}>
      <BaseButton
        type={ButtonTypes.Transparent}
        className={css.closeBtn}
        onClick={onClose}
        data-testid="close-button"
      >
        <CloseIcon fill="#8d8d8d" stroke="#8d8d8d" />
      </BaseButton>

      <div className={css.holder} onClick={(e) => e.stopPropagation()}>
        <Carousel
          showArrows
          showIndicators={false}
          showThumbs={false}
          className={css.carousel}
          dynamicHeight
          selectedItem={photos.findIndex(
            (photo) => photo.id === activePhoto.id
          )}
          // @ts-ignore
          statusFormatter={(currentIdx, total) => {
            if (isCounterExists) {
              return (
                <ProfileMediaCounter
                  key="status"
                  current={currentIdx}
                  total={total}
                  className={css.counter}
                />
              );
            }
            return <></>;
          }}
          renderArrowPrev={(onClickHandler, hasPrev) =>
            hasPrev && (
              <BaseButton
                type={ButtonTypes.Transparent}
                onClick={onClickHandler}
                className={css.prevBtn}
                data-testid="prev-button"
              >
                <CarouselPrevIcon />
              </BaseButton>
            )
          }
          renderArrowNext={(onClickHandler, hasNext) =>
            hasNext && (
              <BaseButton
                onClick={onClickHandler}
                type={ButtonTypes.Transparent}
                className={css.nextBtn}
                data-testid="next-button"
              >
                <CarouselNextIcon />
              </BaseButton>
            )
          }
        >
          {photos.map((photo) => (
            <FullscreenPhoto
              key={photo.id}
              src={photo.url}
              classNames={{
                img: css.img,
              }}
            />
          ))}
        </Carousel>

        {!isMobile &&
          isNotOwner &&
          isControlsVisible &&
          !!userProfile?.ulid_id && (
            <FullscreenControls
              handleLikeClick={handleLikeClick}
              isLiked={userProfile.liked}
              contactId={userProfile.ulid_id}
            />
          )}
      </div>
    </div>
  );
};
