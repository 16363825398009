import React, { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { MirrorService } from 'services/MirrorService';
import { MediaType } from 'types/enums/MediaType';

import { useUserProfileQuery } from 'hooks/queries/useUserProfileQuery';
import { QueryKeys } from 'hooks/useSystemSearchQueries';

import { ReactComponent as MatchIcon } from 'assets/icons/chat/match.svg';

import { Spinner, SpinnerTypes } from 'components/base/Spinner';
import { DeletedUser } from 'components/shared/DeletedUser';
import { LastActivityLabel } from 'components/shared/LastActivityLabel';
import { UserAvatar } from 'components/shared/UserAvatar';
import { UserPaymentStatus } from 'components/shared/UserPaymentStatus';

import { HobbiesAndTraitsSection } from '../HobbiesAndTraitsSection';
import { LookingForSection } from '../LookingForSection';
import { TagWithIcon } from '../TagWithIcon';
import { UserCityField } from '../UserCityField';
import { UserProfileAboutMe } from '../UserProfileAboutMe';
import { UserProfileAboutTags } from '../UserProfileAboutTags';
import { UserProfilePhotoGallery } from '../UserProfilePhotoGallery';
import { UserProfileSection } from '../UserProfileSection';
import { UserProfileWizardInfo } from '../UserProfileWizardInfo';
import { UserRealName } from '../UserRealName';

import css from './userProfileTab.module.sass';

interface Props {
  contactId: string;
  userProfileUlid: string | null;
}

export const UserProfileTab: React.FC<Props> = memo(
  ({ contactId, userProfileUlid }) => {
    const {
      isLoading,
      isError,
      data: userProfile,
    } = useUserProfileQuery(contactId, {
      enabled: !!contactId,
    });

    const userPublicPhotos = useMemo(() => {
      const publicPhotos = userProfile?.photos?.filter(
        (photo) => photo.media_type === MediaType.ProfilePublicPhoto
      );

      if (!publicPhotos?.length) return [];

      const mainPhoto = publicPhotos.find((photoItem) => photoItem.is_main);

      if (mainPhoto)
        return [
          mainPhoto,
          ...publicPhotos.filter((photoItem) => photoItem.id !== mainPhoto.id),
        ];

      return publicPhotos;
    }, [userProfile?.photos]);

    const userPrivatePhotos = useMemo(() => {
      return (
        userProfile?.photos?.filter(
          (photo) => photo.media_type === MediaType.ProfilePrivatePhoto
        ) || []
      );
    }, [userProfile?.photos]);

    return (
      <div className={css.root}>
        {!userProfile && isLoading && (
          <div key="spinner" className={css.centeredBlock}>
            <Spinner type={SpinnerTypes.Dark} size="25px" />
          </div>
        )}

        {(isError || (!!userProfile && !userProfile?.is_active)) && (
          <DeletedUser />
        )}

        {!!userProfile &&
          userProfile?.is_active &&
          !!contactId &&
          !isError &&
          !isLoading && (
            <>
              <div className={css.profileHeader}>
                <Link
                  to={
                    userProfileUlid
                      ? `/user/${contactId}?${QueryKeys.XUlid}=${userProfileUlid}`
                      : `/user/${contactId}`
                  }
                >
                  <UserAvatar
                    gender={userProfile?.gender}
                    size={70}
                    url={MirrorService.resolveImagePath(
                      userProfile?.main_photo.profile_url
                    )}
                    className={css.avatar}
                    isActiveUser
                  />
                </Link>
                <div className={css.profileHeaderBox}>
                  <div className={css.connectionStatusBadge}>
                    {userProfile.is_online ? (
                      <div className={css.onlineBadge}>Online</div>
                    ) : (
                      <LastActivityLabel
                        lastActiveAt={userProfile.last_active_at}
                        hasBackground
                      />
                    )}
                  </div>

                  <div className={css.contact}>
                    {userProfileUlid && !!userProfile?.connection_status && (
                      <MatchIcon className={css.matchIcon} />
                    )}
                    <div
                      className={cx(css.name, {
                        [css.match]:
                          userProfileUlid && !!userProfile?.connection_status,
                      })}
                    >
                      {userProfile?.name}, {userProfile?.age}
                    </div>

                    {userProfileUlid && !!userProfile?.connection_status && (
                      <UserPaymentStatus
                        spendCredits={userProfile?.credits?.spend}
                      />
                    )}
                  </div>

                  {userProfile?.country && (
                    <div className={css.country}>
                      {userProfile?.country.name}
                      {userProfile?.city && `, ${userProfile?.city.name}`}
                    </div>
                  )}
                </div>
              </div>

              {userProfile?.details?.about_me && (
                <UserProfileSection classNames={{ root: css.label }}>
                  <UserProfileAboutMe
                    aboutText={userProfile.details.about_me}
                  />
                </UserProfileSection>
              )}

              {userProfile.details?.goal && (
                <UserProfileSection
                  title="Looking for"
                  classNames={{ root: css.label }}
                >
                  <LookingForSection profileDetails={userProfile.details} />
                </UserProfileSection>
              )}

              {!!userProfile && (
                <UserProfileSection
                  title="About"
                  classNames={{ root: css.label }}
                >
                  {!!userProfileUlid && (
                    <UserRealName
                      contactId={userProfile.ulid_id}
                      userProfileUlid={userProfileUlid}
                    />
                  )}

                  {!!userProfileUlid && (
                    <UserCityField
                      contactId={userProfile.ulid_id}
                      userProfileUlid={userProfileUlid}
                    />
                  )}

                  {!!userProfileUlid && !!userProfile && (
                    <TagWithIcon title="User id">
                      <span className={css.ulid}>{userProfile?.ulid_id}</span>
                    </TagWithIcon>
                  )}

                  <UserProfileAboutTags
                    css={{ tagList: css.tagList }}
                    userProfile={userProfile}
                  />
                </UserProfileSection>
              )}

              {!!Object.keys(userProfile?.wizard_data || {}).length && (
                <UserProfileSection
                  title="Additional info"
                  classNames={{ root: css.label }}
                >
                  <UserProfileWizardInfo
                    wizardInfo={userProfile?.wizard_data}
                  />
                </UserProfileSection>
              )}

              {(!!userProfile?.details?.hobbies ||
                !!userProfile?.details?.traits) && (
                <HobbiesAndTraitsSection
                  hobbies={userProfile?.details?.hobbies}
                  traits={userProfile?.details?.traits}
                  labelStyles={{ root: css.label }}
                />
              )}
              {!!userPublicPhotos.length && (
                <UserProfileSection
                  title="Photos"
                  classNames={{ root: css.label }}
                >
                  <UserProfilePhotoGallery
                    photos={userPublicPhotos}
                    isControlsVisible={false}
                  />
                </UserProfileSection>
              )}
              {!!userPrivatePhotos.length && (
                <UserProfileSection
                  title="Private photos"
                  classNames={{ root: css.label }}
                >
                  <UserProfilePhotoGallery
                    photos={userPrivatePhotos}
                    isControlsVisible={false}
                  />
                </UserProfileSection>
              )}
            </>
          )}
      </div>
    );
  }
);
