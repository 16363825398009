import { useCallback, useRef } from 'react';

import { useEventListener } from './useEventListener';

export const useOutsideClick = (onOutsideClick: () => void) => {
  const rootRef = useRef(null);

  const clickHandler = useCallback(
    (event: Event) => {
      // @ts-ignore
      if (rootRef.current && !rootRef.current?.contains(event.target)) {
        onOutsideClick();
      }
    },
    [onOutsideClick]
  );

  useEventListener(document, 'click', clickHandler);

  return rootRef;
};
