import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { Pages } from 'types/enums/Pages';

import { getAuthenticated } from 'store/auth/selectors';

import { DesktopPageLayout } from 'layout/DesktopLayoutWithSidebar';

import { Default, Mobile } from '../MediaComponents';
import { PrivatePageMobileNavigation } from '../PrivatePageMobileNavigation';

export const AppLayout = () => {
  const { pathname } = useLocation();

  const isAuthenticated = useSelector(getAuthenticated);

  const isEnabledChatInfoWidget = useMemo(() => {
    return pathname.includes(Pages.Dialogs);
  }, [pathname]);

  const isEnabledUserInfoWidget = useMemo(() => {
    return pathname.includes(Pages.Icebreakers);
  }, [pathname]);

  const isEnabledMobileNavigation = useMemo(() => {
    return !![
      Pages.Finder,
      Pages.Dialogs,
      Pages.Mails,
      Pages.MyProfile,
      Pages.FAQ,
      Pages.ContactUs,
      Pages.Settings,
      Pages.Notifications,
    ].find((pathItem) => pathname === pathItem);
  }, [pathname]);

  const isEnabledPresentsInfoWidget = useMemo(() => {
    return !!['/presents'].find((pathItem) => pathname === pathItem);
  }, [pathname]);

  // TODO add Mobile layout component with headers and footers

  if (!isAuthenticated) return <Navigate to="/login" replace />;

  return (
    <>
      <Mobile>
        <>
          <Outlet />
          {isEnabledMobileNavigation && <PrivatePageMobileNavigation />}
        </>
      </Mobile>

      <Default>
        <DesktopPageLayout
          showChatInfo={isEnabledChatInfoWidget}
          showPresentsInfo={isEnabledPresentsInfoWidget}
          showUserInfo={isEnabledUserInfoWidget}
        >
          <Outlet />
        </DesktopPageLayout>
      </Default>
    </>
  );
};
