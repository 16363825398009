import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useUserProfileUlid } from 'hooks/useUserProfileUlid';
import {
  getChatContact,
  getChatUser,
  getIsChatBlockedByContactSelector,
  getIsChatContactLiked,
  getIsConnection,
} from 'store/messenger/selectors';

export const useChatUsers = () => {
  const { contactId = '' } = useParams<{ contactId: string }>();
  const { userProfileUlid } = useUserProfileUlid();

  const contact = useSelector(
    getChatContact({ contactId, userId: userProfileUlid })
  );

  const currentUser = useSelector(
    getChatUser({ contactId, userId: userProfileUlid })
  );

  const isContactLiked = useSelector(
    getIsChatContactLiked({ contactId, userId: userProfileUlid })
  );

  const isConnection = useSelector(
    getIsConnection({ contactId, userId: userProfileUlid })
  );

  const isChatBlockedByContact = useSelector(
    getIsChatBlockedByContactSelector({ contactId, userId: userProfileUlid })
  );

  return {
    contactId,
    contact,
    currentUser,
    userProfileUlid,
    isContactLiked,
    isChatBlockedByContact,
    isConnection,
  };
};
